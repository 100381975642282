
import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link} from 'react-router-dom'

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Footer from "./Footer"
import Nav_men from "./Nav_men"
// import AddIcon from '@material-ui/icons/Add';
import ChatIcon from '@mui/icons-material/Chat';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';



export default function Dash_men() {

  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div>     
    {/* Nav */}
    <Nav_men/>
    {/* end Nav */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900 text-center">Welcome To RIG.jr</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Replace with your content */}
          {/* Avator of the User */}
            <a href="#" class="block relative">
                <img alt="profil" src="https://cdn-icons-png.freepik.com/512/5277/5277627.png" class="mx-auto object-cover rounded-full h-40 w-40 "/>
            </a>
           {/*Avator ends */}
           {/* Features Regarding studing*/}
           {/* <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex flex-col items-center ">
                <h1 className="text-3xl font-bold text-gray-900 text-center">Lets Start Teaching</h1>

 
  <label class="flex items-center relative w-max cursor-pointer select-none">
  <input type="checkbox" class={`appearance-none transition-colors cursor-pointer w-24 h-7 rounded-full focus:outline-none   focus:ring-offset-red focus:ring-blue-500 ${isChecked?"bg-green-500": "bg-red-500"}` 
  }
  checked={isChecked}
  onChange={toggleSwitch}
  />
  {isChecked?<>
    <span class="absolute font-medium text-xs uppercase right-8 text-white"> Online </span>
  <span class="w-7 h-7 right-0 absolute rounded-full transform transition-transform bg-gray-200" />
  </>:<>
  
  <span class="absolute font-medium text-xs uppercase right-3 text-white"> Offline </span>
  <span class="w-7 h-7 left-0 absolute rounded-full transform transition-transform bg-gray-200" />

  </>}


 
</label>

 </div> */}

            {/* <div class="sm:flex flex-wrap justify-center items-center text-center gap-8">
              
                    <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 bg-white mt-6  shadow-lg rounded-lg ">
                   
                   <Link to='/createCourse'> 
                   
                   
                
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <svg class="h-8 w-8 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
</svg>
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Create A course
                    </h3>
                    
                    </Link>
                </div>
              

<div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 bg-white mt-6  shadow-lg rounded-lg ">
                   
                   <Link to='/teacherchat'> 
                   
                   
                
                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            < ChatIcon/>
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        1:1 with students
                    </h3>
                    
                    </Link>
                </div>


                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-4 py-4 mt-6 sm:mt-16 md:mt-20 lg:mt-24 bg-white shadow-lg rounded-lg ">
                    
                <Link to='/search'> 

                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <FormatListBulletedIcon/>
                        </div>
                    </div>
                    <p class="text-md text-gray-500  py-4">
                        List Of Content Created
                    </p>

                    </Link>
                </div>


                <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 mt-6  px-4 py-4 bg-white shadow-lg rounded-lg ">
                  
                <Link to="/teachersubject">

                    <div class="flex-shrink-0">
                        <div class="flex items-center mx-auto justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <HelpOutlineIcon/>
                        </div>
                    </div>
                    <h3 class="text-2xl sm:text-xl text-gray-700 font-semibold  py-4">
                        Answer Questions
                    </h3>
                    </Link>

                </div>

            </div> */}




            <div class="grid md:grid-cols-3 grid-cols-1 gap-4 mt-8"> 


{/* <div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<div class="flex items-center justify-between w-full mb-6">
        <p class="text-xl font-medium text-gray-800 ">
            Courses 
        </p>
       
    </div>

<Link to='/teacherchat'>

<div class="flex items-center justify-between p-3 mb-2 bg-blue-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />  <line x1="8" y1="9" x2="16" y2="9" />  <line x1="8" y1="13" x2="14" y2="13" /></svg>             
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    1:1 with Students
</p>

</div>
</div>
</Link>

<Link to='/teachersubject'>                   

<div class="flex items-center justify-between p-3 mb-2 bg-blue-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M3.5 5.5l1.5 1.5l2.5 -2.5" />  <path d="M3.5 11.5l1.5 1.5l2.5 -2.5" />  <path d="M3.5 17.5l1.5 1.5l2.5 -2.5" />  <line x1="11" y1="6" x2="20" y2="6" />  <line x1="11" y1="12" x2="20" y2="12" />  <line x1="11" y1="18" x2="20" y2="18" /></svg>    </span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    View Doubts
</p>

</div>
</div>
</Link>




</div> */}
<div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<div class="flex items-center justify-between w-full mb-6">
        <p class="text-xl font-medium text-gray-800 ">
            Up Skill 
        </p>
        <Link to='/createCourse'> 
        
        <button class="flex items-center text-gray-800 border-0 hover:text-black   focus:outline-none">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                </path>
            </svg>
        </button>
        </Link>
        
    </div>
    <Link to='/coursesessionstutor'> 

<div class="flex items-center justify-between p-3 mb-2 bg-green-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    My Course List
</p>

</div>
</div>
</Link>


{/* <div class="flex items-center justify-between p-3 mb-2 bg-purple-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    Student List
</p>

</div>
</div> */}


</div>
<div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<div class="flex items-center justify-between w-full mb-6">
        <p class="text-xl font-medium text-gray-800 ">
            Dzongkha Session 
        </p>
        <Link to='/createCoursedzongkha'> 
        
        <button class="flex items-center text-gray-800 border-0 hover:text-black   focus:outline-none">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                </path>
            </svg>
        </button>
        </Link>
       
        
    </div>
    <Link to='/dzongkhasessionstutor'> 

<div class="flex items-center justify-between p-3 mb-2 bg-green-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    My Course List
</p>

</div>
</div>
</Link>


{/* <div class="flex items-center justify-between p-3 mb-2 bg-purple-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    Student List
</p>

</div>
</div> */}


</div>
<div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<div class="flex items-center justify-between w-full mb-6">
        <p class="text-xl font-medium text-gray-800 ">
            Rig Jr Academic 
        </p>
        <Link to='/createCourseacademic'> 
        
        <button class="flex items-center text-gray-800 border-0 hover:text-black   focus:outline-none">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                </path>
            </svg>
        </button>
        </Link>
       
        
    </div>
    <Link to='/academicsessionstutor'> 

<div class="flex items-center justify-between p-3 mb-2 bg-green-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    My Course List
</p>

</div>
</div>
</Link>


{/* <div class="flex items-center justify-between p-3 mb-2 bg-purple-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    Student List
</p>

</div>
</div> */}


</div>



{/* <div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<p class="text-xl text-center font-medium text-gray-800  mb-2">
Rig Jr Academy 
</p>
<Link to='/createCourseacademic'> 
        
        <button class="flex items-center text-gray-800 border-0 hover:text-black   focus:outline-none">
            <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1600 736v192q0 40-28 68t-68 28h-416v416q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-416h-416q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h416v-416q0-40 28-68t68-28h192q40 0 68 28t28 68v416h416q40 0 68 28t28 68z">
                </path>
            </svg>
        </button>
        </Link>
</div> */}


</div>


            {/* Features end */}
           
          {/* /End replace */}
        </div>
      </main>
       {/* Footer */}
       <Footer/>
       {/* end Footer */}
    </div>
  )
}
