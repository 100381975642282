
import React,{useContext, useState,useEffect} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';
import {socket} from "../App"
import Footer from '../components/Footer';
import Nav from './Nav';
 


const PrejoiningCourse = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [name,setName] =  useState("");
  const [phone,setPhone] =  useState("");
  const [password,setPassword] =  useState("");
  const [billing,setBilling] =  useState(false);
  const [payment,setPayment] =  useState(false);
  const [freeEvent,setFreeEvent] =  useState(false);
  const [policy,setPolicy] =  useState(false);
  const [seats,setSeats] =  useState(0);
  const [size,setSize] =  useState(0);
  const navigate= useNavigate()

  const [data,setData]= useState([])
  const [cart,setCart]= useState([])
  const [details,setDetails]= useState([])
  const [column, setColumn] = useState("")
  const [date, setDate] = useState(new Date())
  const [eventDate, seteventDate] = useState(new Date().setHours(0, 0, 0, 0))
  const [booked,setBooked] = useState([])
  const [taken,setTaken] = useState([])
const {dzongkhaid} = useParams()
// const elements = useElements()



useEffect(()=>{

    fetch(`/singlecoursesessions/${dzongkhaid}`,{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.post)

      console.log(result.post)
    })
     

},[])

 

// const askToJoin =()=>{

//     fetch('/addstudentsdzongkhasessions',{

//       method:"put",
//       headers:{

//         "Content-Type":"application/json",
//         Authorization: "Bearer " +localStorage.getItem("jwt")



//       },
//       body:JSON.stringify({
//         postId:dzongkhaid,
//         date
//       })

//     }).then(res=> res.json())
//       .then(results=>{

//         history('/StudentDashboard')
        
//       })



//   }

       

    return(

<>
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            <Nav/>

            {/* <Header></Header> */}

            


<div class="relative md:block lg:block hidden">

{/* <img src="bann.jpeg" class="absolute h-full w-full object-cover"/> */}
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
  </div>


 {/* <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-8 xl:py-12">
        <div class="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
            <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-6xl text-white leading-tight mt-2 ">
            Elevate Your Events, Simplify Your Tickets
                <br/>
                <br/>
                <br/>
                
          
            </h1>
           
        </div>
    </div>
    */}
   
    </div>


    



        </div>
     

    </div>



  

   
<div class="flex flex-col ">

<img src="/ww.png" class="absolute h-full w-full md:object-cover object-contain overflow-hidden   "/>
{/* 
<video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-1/2  md:w-full md:object-cover">
<source src="https://v4.cdnpk.net/videvo_files/video/free/video0467/large_watermarked/_import_614f00b3e16dc1.55454574_FPpreview.mp4" type="video/mp4" />
</video> */}
<div>
{/* <img 
  src={data.url 
    ? data.url[0].replace('/upload/', '/upload/e_bgremoval/') 
    : "https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph"} 
  className="relative h-60 w-11/12 ml-4 md:h-96 md:w-1/2 object-center md:ml-80 md:mt-24 mt-32 rounded-lg" 
/> */}
</div>





<div class="flex items-center justify-center min-h-screen">
    <div class="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8  mb-4">
        <div class="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg pricing-box lg:max-w-none lg:flex">
            <div class="px-6 py-8 bg-gray-50 dark:bg-gray-800 lg:flex-shrink-1 lg:p-12">
                <h3 class="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9 dark:text-white">
                    {data ? data.title : ""}
                </h3>
                <p class="mt-6 text-base leading-6 text-gray-500 dark:text-gray-200">
                    {data ? data.body : ""}
                </p>
                <img 
                    src={data.url ? data.url[0] : "https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph"} 
                    className="relative h-60 w-11/12 md:h-96 md:w-full object-center mt-2 rounded-lg" 
                />
            </div>
            <div class="px-6 py-8 text-center bg-gray-300 dark:bg-gray-700 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                <div class="mt-6">
                    <div class="rounded-md shadow">
                        
                        <a href={data.googlemeet}>
                        
                        <button type="button" class="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                       
                       
                       >
                            Join Live Class
                        </button>

                        </a>

                        {data.classid?<>
                        
                        <a href={data.classid}>
                        
                        <button type="button" class="mt-8 py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                       
                       
                       >
                            Google Classroom
                        </button>

                        </a>
                        </>:<></>}
                        
                        {/* <a href={data.join_url}>
                        
                        <button type="button" class="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                       
                       
                       >
                            Ask to Join
                        </button>

                        </a> */}

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

{/* <div class="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 mt-24 mb-4">
    <div class="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg pricing-box lg:max-w-none lg:flex">
        <div class="px-6 py-8 bg-gray-50 dark:bg-gray-800 lg:flex-shrink-1 lg:p-12">
            <h3 class="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9 dark:text-white">
                {data?data.title:""}
            </h3>
            <p class="mt-6 text-base leading-6 text-gray-500 dark:text-gray-200">
                {data?data.body:""}
            </p>
            <img 
  src={data.url 
    ? data.url[0] 
    : "https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph"} 
  className="relative h-60 w-11/12  md:h-96 md:w-full object-center   mt-2 rounded-lg" 
/>
        </div>
        <div class="px-6 py-8 text-center bg-gray-300 dark:bg-gray-700 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
           
       
            <div class="mt-6">
                <div class="rounded-md shadow">
                    <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                    onClick={()=>{startChat()}}
                    >
                        Ask to Join
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
 */}


{
policy? 
(
<>
<div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>
 {/*content*/}
 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   {/*header*/}
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setPolicy(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
   {/*body*/}
  
  <h1 className='text-lg text-center'>
  Refund and Cancellation Policy

  </h1>


<p className='text-gray-400 text-sm m-2'>
Thank you for purchasing tickets through B5 Events. Please review our refund and cancellation policy carefully:

No Refunds for Missed Events: We do not offer refunds if you do not attend the event. All ticket sales are final.

Ticket Delivery Issues: If you do not receive your tickets after purchase, please contact us immediately. Send an email to info@b5events.com with your order details, and we will assist in resolving the issue.

No Refund for Non-Receipt of Tickets Without Prior Contact: Failure to receive tickets without informing us will not be eligible for a refund. It is your responsibility to notify us if there is an issue.

Event Cancellations: In the rare event of a cancellation by B5 Events, a full refund will be provided.

By making a purchase, you agree to these terms.

</p>







 

</div>


</div>
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}


</div>
        














<Footer page="events"></Footer>
</main>







</>

  


    );
}

export default PrejoiningCourse;