
import React,{useContext, useState,useEffect} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from './Header';
import {socket} from "../App"
import Footer from './Footer';
import Nav from './Nav';
import Nav_men from './Nav_men';
 
import logo from "./logo.png"


const SingleCourseDash = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [name,setName] =  useState("");
  const [phone,setPhone] =  useState("");
  const [password,setPassword] =  useState("");
  const [billing,setBilling] =  useState(false);
  const [payment,setPayment] =  useState(false);
  const [freeEvent,setFreeEvent] =  useState(false);
  const [policy,setPolicy] =  useState(false);
  const [seats,setSeats] =  useState(0);
  const [size,setSize] =  useState(0);
  const navigate= useNavigate()
  const [classroom,setClassRoom] =  useState("");
    const [addLink,setAddLink] =  useState(false);
  const [data,setData]= useState([])
  const [cart,setCart]= useState([])
  const [details,setDetails]= useState([])
  const [column, setColumn] = useState("")
  const [date, setDate] = useState(new Date())
  const [eventDate, seteventDate] = useState(new Date().setHours(0, 0, 0, 0))
  const [booked,setBooked] = useState([])
  const [taken,setTaken] = useState([])
const {dzongkhaid} = useParams()
// const elements = useElements()



useEffect(()=>{

    fetch(`/singlecoursesessions/${dzongkhaid}`,{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.post)

      console.log("POH",result.post)
    })
     

},[])

const addClass =()=>{

    fetch('/startgoogleclasscourse',{

      method:"put",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")



      },
      body:JSON.stringify({
        postId:dzongkhaid,
        googleclassroom:classroom
      })

    }).then(res=> res.json())
      .then(results=>{

        window.location.reload()
        // history('/StudentDashboard')
        
      })



  }


const startChat =()=>{

    fetch('/addstudentscoursesessions',{

      method:"put",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")



      },
      body:JSON.stringify({
        postId:dzongkhaid,
        date
      })

    }).then(res=> res.json())
      .then(results=>{

        // history('/StudentDashboard')
        
      })



  }

  const StartMeeting = (postId,topic)=>{

    fetch(`/createCourseMeeting`,{
        method:"post",
  headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer " +localStorage.getItem("jwt")

  },
  body: JSON.stringify({
    postId: dzongkhaid,
    topic:data.title
    
  })
    }).then(res=>res.json())
    .then(result=>{
        

        window.location.href= result.start_url


    }).catch(err=>{
        console.log(err)
    })
}

const startGoogleMeet =()=>{

    fetch('/newgooglemeetcourse',{

      method:"put",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")



      },
      body:JSON.stringify({
        postId:dzongkhaid,
        date
      })

    }).then(res=> res.json())
      .then(results=>{
        window.location.reload()

        // history('/StudentDashboard')
        
      })



  }

    return(

<>
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            <Nav_men/>

            {/* <Header></Header> */}

            


            <main class="relative h-screen overflow-hidden bg-gray-100 dark:bg-gray-800 rounded-2xl">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
           
                <div class="h-screen pt-2 pb-24 pl-2 pr-2 overflow-auto md:pt-0 md:pr-0 md:pl-0">
                    <div class="flex flex-col flex-wrap sm:flex-row ">
                        <div class="w-full sm:w-1/2 xl:w-1/3">
                            <div class="mb-4">
                                <div class="w-full p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-700">
                                    <div class="flex items-center justify-between mb-6">
                                        <div class="flex items-center">
                                              <img className='h-12 w-12' src={logo}/>
                                            <div class="flex flex-col">
                                                <span class="ml-2 font-bold text-black text-md dark:text-white">
                                                    {data.title}
                                                </span>
                                                <span class="ml-2 text-sm text-gray-500 dark:text-white">
                                                   {data.body}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="flex items-center">
                                                <Link to={`/editcourserecord/${dzongkhaid}`}>
                                                                                                                               
                                                                                                                               <button class="px-4 border border-gray-200 bg-red-400 text-white rounded-lg">
                                                                                                                                   Edit
                                                                                                                               </button>
                                                                                                                               </Link>
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-center mb-4 space-x-12">

                                    {data.googlemeet?<>
                                    <a href={data.googlemeet}>

                                            {/* <img src='https://cdn4.iconfinder.com/data/icons/logos-brands-in-colors/48/google-meet-512.png' className='h-52 w-52'></img> */}
                                            <span type="button" class="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-1/2 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">

                                            Join Google Meet
                                        </span>
                                    </a>
                                  
                                        
                                    </>:<>
                                    
                                    <span class="flex cursor-pointer items-center px-2 py-1 text-xs font-semibold text-red-400 bg-white border border-red-400 rounded-md"
                                       onClick={()=>{startGoogleMeet()}}
                                       
                                       >
                                            Enable Google Meet
                                        </span>

                                    </>}
                                       



                                        {data.classid?
                                        <a href={data.classid}>
                                            <span type="button" class="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-1/2 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                            Enter Google Classroom
                                    </span>
                                    </a>

                                    
                                    :
                                     <span class="flex cursor-pointer items-center px-2 py-1 text-xs font-semibold text-red-400 bg-white border border-red-400 rounded-md"
                                     
                                     onClick={()=>{setAddLink(true)}}
                                     >
                                     Add Google Classroom +
                                 </span>
                                    }
                                       
                                    </div>
                                
                                
                                    {/* <button type="button" class="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-1/2 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                        onClick={() => { StartMeeting(); }}
                        >
                            Start Session
                        </button> */}

                                </div>
                            </div>
                           
                        </div>
                        <div class="w-full sm:w-1/2 xl:w-1/3">
                            <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full bg-white shadow-lg rounded-2xl dark:bg-gray-700">
                                    <p class="p-4 font-bold text-black text-md dark:text-white">
                                        Students
                                        <span class="ml-2 text-sm text-gray-500 dark:text-gray-300 dark:text-white">
                                            ({data.students?data.students.length:""})
                                        </span>
                                    </p>
                                    <ul>
                                        {data.students? data.students.map(item=>{

                                            return(
                                                 <li class="flex items-center justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                                                <div class="flex items-center justify-start text-sm">
                                                    <span class="mx-4">
                                                        01
                                                    </span>
                                                    <span>
                                                        {item.student.name}
                                                    </span>
                                                    <span class="mx-4">
                                                        {item.student.email}
                                                    </span>
                                                </div>
                                                <svg width="20" height="20" fill="currentColor" class="mx-4 text-gray-400 dark:text-gray-300" viewBox="0 0 1024 1024">
                                                    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" fill="currentColor">
                                                    </path>
                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z" fill="currentColor">
                                                    </path>
                                                </svg>
                                            </li>)
                                        }):<></>}
                                       
                                     
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </main>


    



        </div>
     

    </div>



  

{/*    
<div class="flex flex-col ">

<img src="/wall.png" class="absolute h-1/2 w-full md:object-cover object-fit overflow-hidden  "/>

<div>
<img 
  src={data.url
    ? data.url[0]
    : "https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph"} 
  className="relative h-60 w-11/12 ml-4 md:h-96 md:w-1/2 object-center md:ml-80 md:mt-24 mt-32 rounded-lg" 
/>
</div>










<div class="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 mt-2 mb-4">
    <div class="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg pricing-box lg:max-w-none lg:flex">
        <div class="px-6 py-8 bg-gray-50 dark:bg-gray-800 lg:flex-shrink-1 lg:p-12">
            <h3 class="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9 dark:text-white">
                {data?data.title:""}
            </h3>
            <p class="mt-6 text-base leading-6 text-gray-500 dark:text-gray-200">
                {data?data.body:""}
            </p>
            <div class="mt-8">
                <div class="flex items-center">
                    <h4 class="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-indigo-600 uppercase bg-gray-50 dark:bg-gray-800">
                        What&#x27;s included
                    </h4>
                    <div class="flex-1 border-t-2 border-gray-200">
                    </div>
                </div>
                <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            All illimited components
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Own custom Tailwind styles
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Unlimited Templates
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Free premium dashboard
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Best ranking
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Prenium svg
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            My 
                        </p>
                    </li>
                </ul>
            </div>
            <div class="mt-8">
                <div class="flex items-center">
                    <h4 class="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-indigo-600 uppercase bg-gray-50 dark:bg-gray-800">
                        &amp; What&#x27;s not
                    </h4>
                </div>
                <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="w-6 h-6 mr-2" fill="red" viewBox="0 0 1792 1792">
                                <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            No Contracts. No monthly, setup, or additional payment processor fees
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="w-6 h-6 mr-2" fill="red" viewBox="0 0 1792 1792">
                                <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            No 2-week on-boarding, it takes 20 minutes!
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="px-6 py-8 text-center bg-gray-50 dark:bg-gray-700 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
            <p class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                Fee
            </p>
            <div class="flex items-center justify-center mt-4 text-5xl font-extrabold leading-none text-gray-900 dark:text-white">
                <span>
                    ${data.fee}
                </span>
            </div>
            <p class="mt-4 text-sm leading-5">
                <span class="block font-medium text-gray-500 dark:text-gray-400">
                    Card payments:
                </span>
                <span class="inline-block font-medium text-gray-500  dark:text-gray-400">
                    2.9% + 20p per transaction
                </span>
            </p>
            <div class="mt-6">
                <div class="rounded-md shadow">
                    <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                    onClick={()=>{startChat()}}
                    >
                        Enroll Now
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>



{
policy? 
(
<>
<div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>
 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setPolicy(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
  
  <h1 className='text-lg text-center'>
  Refund and Cancellation Policy

  </h1>


<p className='text-gray-400 text-sm m-2'>
Thank you for purchasing tickets through B5 Events. Please review our refund and cancellation policy carefully:

No Refunds for Missed Events: We do not offer refunds if you do not attend the event. All ticket sales are final.

Ticket Delivery Issues: If you do not receive your tickets after purchase, please contact us immediately. Send an email to info@b5events.com with your order details, and we will assist in resolving the issue.

No Refund for Non-Receipt of Tickets Without Prior Contact: Failure to receive tickets without informing us will not be eligible for a refund. It is your responsibility to notify us if there is an issue.

Event Cancellations: In the rare event of a cancellation by B5 Events, a full refund will be provided.

By making a purchase, you agree to these terms.

</p>







 

</div>


</div>
</>
)


:null

}


</div>
         */}




{
addLink? 
(
<>

<div className="fixed inset-0   z-50 flex justify-center items-center overflow-auto ">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setAddLink(false)}
        >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}          
     {/* <div className='h-60 overflow-y-auto'> */}

     <p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Add Google Classroom Link
                  </p>
      <div className="flex flex-col items-center justify-center p-6">


      
        <div className="w-full">

          {/* Nu. {amount} */}
          <input
            type="text"
            className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300 focus:outline-none mb-4"
            placeholder="Add your Google Classroom Link Here"
            value={classroom}
            onChange={(e) => setClassRoom(e.target.value)}
          />
        
          <button
            type="button"
            className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            onClick={() => addClass()}
          >
            Add +
          </button>
        </div>
    
        </div>


      {/* </div> */}
    </div>
  </div>
</div>




</>
)


:null

}










<Footer page="events"></Footer>
</main>







</>

  


    );
}

export default SingleCourseDash;