import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate} from 'react-router-dom'
import Nav from "./Nav"
import Footer from "./Footer"
import SubjectsCards from './SubjectsCards'
import SearchIcon from '@mui/icons-material/Search';
import {UserContex} from '../App'
import Nav_admin from './nav_admin';


function AdminDzongkhaList() {

  const searchModal = useRef(null)
  const [search,setSearch] = useState('')
  const [userDetails, setUserDetails] = useState([])
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  useEffect(() => {
    // const user = localStorage.getItem('user')?.replace(/"/g, ''); // Remove quotes from localStorage value
  var user
    fetch('/supergetdzongkhasessions', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(res => res.json())
      .then(result => {
      
        if (!result.post || !Array.isArray(result.post)) return;
  
        console.log("User ID from localStorage:", user); // Debugging
        console.log("API Response:", result.post);
  
        // Function to clean string by removing non-printable characters and trimming it
        const cleanString = (str) => {
          return String(str)?.replace(/\s+/g, '').trim(); // Remove all whitespace and extra spaces
        };
  
        // Function to check if the user is enrolled in a course
        const isStudentInCourse = (students, userId) => {
          return students.some(student => {
            // Ensure student ID is treated as a string for comparison
            const studentId = cleanString(String(student.student)); // Convert ObjectId to string and clean it
            const cleanUser = cleanString(result.user); // Clean the user ID
            console.log("Checking Student:", studentId, "against", cleanUser);
            return studentId === cleanUser; // Compare cleaned strings
          });
        };
  
        // Filter out courses where the user is already enrolled
        const filteredCourses = result.post.filter(course => !isStudentInCourse(course.students, result.user));
  
        console.log("Filtered Courses:", filteredCourses); // Debugging
        
        setUserDetails(filteredCourses);
      })
      .catch(error => console.error("Error fetching sessions:", error));
  }, []);
  
  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-dzongkhasessions',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setUserDetails(results.user)
      })


  }
    


    return (
        <div>



<main class="relative h-screen overflow-hidden bg-gray-100 dark:bg-gray-800">
    <div class="flex items-start justify-between">
        <div class="relative hidden h-screen shadow-lg lg:block w-80">
            <div class="h-full bg-white dark:bg-gray-700">
                <div class="flex items-center  pt-6 ml-8">
                    <p class="text-xl text-center font-bold dark:text-white">
                        Rig Jr.
                    </p>
                </div>
               <Nav_admin/>
            </div>
        </div>
        <div class="flex flex-col w-full md:space-y-4">
          
            <div class="h-screen px-4 pb-24 overflow-auto md:px-6">
               
                
                <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Search For Courses</h1>
          <div class="flex relative ">
          <span class="mt-2 inline-flex  items-center px-3 text-sm">
            <SearchIcon/>        
    </span>

        
    
    <input type="text" id="" class=" mt-2 rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="" placeholder="Enter Search"
    
    value= {search} onChange={(e)=>fetchPost(e.target.value)}
    
    />
    
    </div>
        </div>

        
        
      </header>
      <main>
            <div class="grid md:grid-cols-3 grid-cols-1 p-4 justify-center items-center text-center gap-2 bg-gray-200 " >
              {/* dynamicaaly load code here */}

              {
                userDetails.map(item=>{

                  return(


                    <Link to={"/singleacademic/"+item._id}>

                    
                    {/* <div class="flex items-center justify-center px-5 py-5 m-2">
                <div class="w-full mx-auto max-w-xl rounded-lg bg-white  shadow-lg px-5 pt-5 pb-10 text-gray-800 ">
                    <div class="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
                        <a href="#" class="block relative">
                            <img alt="profil" 
                            
                            src="https://images.unsplash.com/photo-1573496799652-408c2ac9fe98?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
                            class="mx-auto object-cover rounded-full h-20 w-20 "
                            
                            
                            />
                        </a>
                    </div>


                    <div class="w-full mb-10">
                    < h2 class="text-lg font-bold text-black text-center px-5">
                            {item.title}
                        </h2>
                        <p class="text-sm text-gray-600  text-center px-5">
                            {item.body}
                        </p>
                    </div>
                    <div class="w-full">
                        <p class="text-md text-indigo-500 font-bold text-center">
                        {item.teacher.name}
                        </p>
                        <p class="text-xs text-gray-500  text-center">
                          {item.teacher.email}
                        </p>
                    </div>
                </div>
            </div> */}


<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90  w-80">
    <a href="#" class="block w-full h-full">
        <img alt="blog photo" src={item.url[0]} class="object-cover w-full max-h-40"/>
        <div class="w-full p-4 bg-white dark:bg-gray-800">
            <p class="font-medium text-indigo-500 text-md">
            </p>
            <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                {item.title}
            </p>
            <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                {
                  item.body.substring(0,50) 
                }       Read More....     </p>
        </div>
    </a>
</div>

            </Link>
                  )

                })
              }

            </div>

      </main>
            </div>
        </div>
    </div>
</main>
             {/* Nav */}
            {/* end Nav */}
         
            {/* Footer */}
            <Footer/>
            {/* end Footer */}
        </div>
    )
}

export default AdminDzongkhaList
