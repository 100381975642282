import { Outlet, Navigate } from 'react-router-dom'


const ProtectedRoutesAdmin = () => {

    const user = JSON.parse( localStorage.getItem("superadmin"))



    return(
        user ? <Outlet/> : <Navigate to="/"/>);
}

export default ProtectedRoutesAdmin