import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import {UserContex} from '../App'
import logo from './logo.png'


const navigation = ['My Dash',"Schedules"]
const profile = ['Sign out']

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Nav_men() {

  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

    return (
       
<div>
    <nav class="bg-white shadow py-4 ">
        <div class="px-8 mx-auto max-w-7xl">
            <div class="flex items-center justify-between h-16">
                <div class=" flex items-center">
                    <a class="flex-shrink-0" href="/MentorDash">
                        <img class="w-8 h-8" src={logo} alt="Workflow"/>
                    </a>
                    <div class="hidden md:block">
                        <div class="flex items-baseline ml-10 space-x-4">
                            <a class="text-gray-300  text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium" href="/MentorDashboard">
                                Dashboard
                            </a>
                            <a class="text-gray-800 dark:text-white  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-md font-medium" href="/MentorSchedule">
                                Schedule
                            </a>
                           
                         
                        </div>
                    </div>
                </div>
                <div class="block">
                    <div class="flex -mr-2 md:block">
                      
                                <a class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" href='/myprofile'>
                                    My Profile
                                </a>
                        </div>
                        <div class="flex items-center ml-4 md:ml-6">
                        </div>
                    </div>
                
                </div>
            </div>
        
            </nav>
        </div>


    )
}

export default Nav_men
