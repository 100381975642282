
import React,{useContext, useState,useEffect} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';
import {socket} from "../App"
import Footer from '../components/Footer';
import Nav from './Nav';
import Nav_men from './Nav_men';
 
import logo from "./logo.png"


const SingleDzongkhaDash = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [name,setName] =  useState("");
  const [phone,setPhone] =  useState("");
    const [classroom,setClassRoom] =  useState("");
    const [addLink,setAddLink] =  useState(false);
  const [password,setPassword] =  useState("");
  const [billing,setBilling] =  useState(false);
  const [payment,setPayment] =  useState(false);
  const [freeEvent,setFreeEvent] =  useState(false);
  const [policy,setPolicy] =  useState(false);
  const [seats,setSeats] =  useState(0);
  const [size,setSize] =  useState(0);
  const navigate= useNavigate()

  const [data,setData]= useState([])
  const [cart,setCart]= useState([])
  const [details,setDetails]= useState([])
  const [column, setColumn] = useState("")
  const [date, setDate] = useState(new Date())
  const [eventDate, seteventDate] = useState(new Date().setHours(0, 0, 0, 0))
  const [booked,setBooked] = useState([])
  const [taken,setTaken] = useState([])
const {dzongkhaid} = useParams()
// const elements = useElements()



useEffect(()=>{

    fetch(`/singledzongkhasessions/${dzongkhaid}`,{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{
      setData(result.post)

      console.log("POH",result.post)
    })
     

},[])

const addClass =()=>{

    fetch('/startgoogleclassdzongkha',{

      method:"put",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")



      },
      body:JSON.stringify({
        postId:dzongkhaid,
        googleclassroom:classroom
      })

    }).then(res=> res.json())
      .then(results=>{

        window.location.reload()
        // history('/StudentDashboard')
        
      })



  }


const startChat =()=>{

    fetch('/addstudentsdzongkhasessions',{

      method:"put",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")



      },
      body:JSON.stringify({
        postId:dzongkhaid,
        date
      })

    }).then(res=> res.json())
      .then(results=>{

        history('/StudentDashboard')
        
      })



  }

  const StartMeeting = (postId,topic)=>{

    fetch(`/createMeeting`,{
        method:"post",
  headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer " +localStorage.getItem("jwt")

  },
  body: JSON.stringify({
    postId: dzongkhaid,
    topic:data.title
    
  })
    }).then(res=>res.json())
    .then(result=>{
        

        window.location.href= result.start_url


    }).catch(err=>{
        console.log(err)
    })
}



const startGoogleMeet =()=>{

    fetch('/newgooglemeetdzongkha',{

      method:"put",
      headers:{

        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")



      },
      body:JSON.stringify({
        postId:dzongkhaid,
        date
      })

    }).then(res=> res.json())
      .then(results=>{
        window.location.reload()

        // history('/StudentDashboard')
        
      })



  }
    return(

<>    

    <Nav_men/>

<main class="bg-white h-screen  relative overflow-x-hidden">        

    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">


            {/* <Header></Header> */}

            


            <main class="relative h-screen overflow-hidden bg-gray-100 dark:bg-gray-800 rounded-2xl">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
           
                <div class="h-screen pt-2 pb-24 pl-2 pr-2 overflow-auto md:pt-0 md:pr-0 md:pl-0">
                    <div class="flex flex-col flex-wrap sm:flex-row ">
                        <div class="w-full sm:w-1/2 xl:w-1/3">
                            <div class="mb-4">
                                <div class="w-full p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-700">
                                    <div class="flex items-center justify-between mb-6">
                                        <div class="flex items-center">
                                              <img className='h-12 w-12' src={logo}/>
                                            <div class="flex flex-col">
                                                <span class="ml-2 font-bold text-black text-md dark:text-white">
                                                    {data.title}
                                                </span>
                                                <span class="ml-2 text-sm text-gray-500 dark:text-white">
                                                   {data.body}
                                                </span>
                                            </div>
                                        </div>
                                         <div class="flex items-center">
                                                                                   <Link to={`/editdzongkharecord/${dzongkhaid}`}>
                                                                                   
                                                                                   <button class="px-4 border border-gray-200 bg-red-400 text-white rounded-lg">
                                                                                       Edit
                                                                                   </button>
                                                                                   </Link>
                                       
                                                                                   {/* <button class="text-gray-200">
                                                                                       <svg width="25" height="25" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                                                           <path d="M1088 1248v192q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68zm0-512v192q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68zm0-512v192q0 40-28 68t-68 28h-192q-40 0-68-28t-28-68v-192q0-40 28-68t68-28h192q40 0 68 28t28 68z">
                                                                                           </path>
                                                                                       </svg>
                                                                                   </button> */}
                                                                               </div>
                                    </div>
                                    <div class="flex items-center justify-center mb-4 space-x-12">
                                    {data.googlemeet?<>
                                    <a href={data.googlemeet}>

                                    <span type="button" class="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-1/2 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">

                                            Join Google Meet
                                        </span>
                                    </a>
                                  
                                        
                                    </>:<>
                                    
                                    <span class="flex cursor-pointer items-center px-2 py-1 text-xs font-semibold text-red-400 bg-white border border-red-400 rounded-md"
                                       onClick={()=>{startGoogleMeet()}}
                                       
                                       >
                                            Enable Google Meet
                                        </span>

                                    </>}
                                        {data.classid?
                                        <a href={data.classid}>
                                            <span type="button" class="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-1/2 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg">
                                            Enter Google Classroom
                                    </span>
                                    </a>

                                    
                                    :
                                     <span class="flex cursor-pointer items-center px-2 py-1 text-xs font-semibold text-red-400 bg-white border border-red-400 rounded-md"
                                     
                                     onClick={()=>{setAddLink(true)}}
                                     >
                                     Add Google Classroom +
                                 </span>
                                    }
                                       
                                    </div>
                                    {/* <div class="block m-auto">
                                        <div>
                                            <span class="inline-block text-sm text-gray-500 dark:text-gray-100">
                                                Task done :
                                                <span class="font-bold text-gray-700 dark:text-white">
                                                    25
                                                </span>
                                                /50
                                            </span>
                                        </div>
                                        <div class="w-full h-2 mt-2 bg-gray-200 rounded-full">
                                            <div class="w-1/2 h-full text-xs text-center text-white bg-purple-500 rounded-full">
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div class="flex items-center justify-start my-4 space-x-4">
                                        <span class="flex items-center px-2 py-1 text-xs font-semibold text-green-500 rounded-md bg-green-50">
                                            IOS APP
                                        </span>
                                        <span class="flex items-center px-2 py-1 text-xs font-semibold text-blue-500 bg-blue-100 rounded-md">
                                            UI/UX
                                        </span>
                                    </div> */}



                                    {/* ZOOM */}


                                    {/* <button type="button" class="py-2 px-4 bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white w-1/2 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                        onClick={() => { StartMeeting(); }}
                        >
                            Start Session
                        </button> */}

                                </div>
                            </div>
                           
                        </div>
                        <div class="w-full sm:w-1/2 xl:w-1/3">
                            <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full bg-white shadow-lg rounded-2xl dark:bg-gray-700">
                                    <p class="p-4 font-bold text-black text-md dark:text-white">
                                        Students
                                        <span class="ml-2 text-sm text-gray-500 dark:text-gray-300 dark:text-white">
                                            ({data.students?data.students.length:""})
                                        </span>
                                    </p>
                                    <ul>
                                        {data.students? data.students.map(item=>{

                                            return(
                                                 <li class="flex items-center justify-between py-3 text-gray-600 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800">
                                                <div class="flex items-center justify-start text-sm">
                                                    <span class="mx-4">
                                                        01
                                                    </span>
                                                    <span>
                                                        {item.student.name}
                                                    </span>
                                                    <span class="mx-4">
                                                        {item.student.email}
                                                    </span>
                                                </div>
                                                <svg width="20" height="20" fill="currentColor" class="mx-4 text-gray-400 dark:text-gray-300" viewBox="0 0 1024 1024">
                                                    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" fill="currentColor">
                                                    </path>
                                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z" fill="currentColor">
                                                    </path>
                                                </svg>
                                            </li>)
                                        }):<></>}
                                       
                                     
                                    </ul>
                                </div>
                            </div>



                            {
addLink? 
(
<>

<div className="fixed inset-0   z-50 flex justify-center items-center overflow-auto ">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setAddLink(false)}
        >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}          
     {/* <div className='h-60 overflow-y-auto'> */}

     <p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Add Google Classroom Link
                  </p>
      <div className="flex flex-col items-center justify-center p-6">


      
        <div className="w-full">

          {/* Nu. {amount} */}
          <input
            type="text"
            className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300 focus:outline-none mb-4"
            placeholder="Add your Google Classroom Link Here"
            value={classroom}
            onChange={(e) => setClassRoom(e.target.value)}
          />
        
          <button
            type="button"
            className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            onClick={() => addClass()}
          >
            Add +
          </button>
        </div>
    
        </div>


      {/* </div> */}
    </div>
  </div>
</div>




</>
)


:null

}

                            {/* <div class="mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full bg-white shadow-lg rounded-2xl dark:bg-gray-700">
                                    <div class="flex items-center justify-between p-4">
                                        <p class="font-bold text-black text-md dark:text-white">
                                            Google
                                        </p>
                                        <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 rounded">
                                            <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                                <g fill="none">
                                                    <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                                    </path>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                     <div class="flex items-center justify-between px-4 py-2 text-gray-600 bg-blue-100 border-l-4 border-blue-500 dark:bg-gray-800">
                                        <p class="flex items-center text-xs dark:text-white">
                                            <svg width="20" height="20" fill="currentColor" class="mr-2 text-blue-500" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path d="M12 5a8.5 8.5 0 1 1 0 17a8.5 8.5 0 0 1 0-17zm0 3a.75.75 0 0 0-.743.648l-.007.102v4.5l.007.102a.75.75 0 0 0 1.486 0l.007-.102v-4.5l-.007-.102A.75.75 0 0 0 12 8zm7.17-2.877l.082.061l1.149 1a.75.75 0 0 1-.904 1.193l-.081-.061l-1.149-1a.75.75 0 0 1 .903-1.193zM14.25 2.5a.75.75 0 0 1 .102 1.493L14.25 4h-4.5a.75.75 0 0 1-.102-1.493L9.75 2.5h4.5z" fill="currentColor">
                                                    </path>
                                                </g>
                                            </svg>
                                            Create wireframe
                                        </p>
                                        <div class="flex items-center">
                                            <span class="ml-2 mr-2 text-xs font-bold dark:text-gray-200 md:ml-4">
                                                25 min 20s
                                            </span>
                                            <button class="p-1 mr-4 text-sm text-gray-400 bg-blue-500 border rounded">
                                                <svg width="17" height="17" fill="currentColor" viewBox="0 0 24 24" class="text-white">
                                                    <g fill="none">
                                                        <path d="M9 6a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1z" fill="currentColor">
                                                        </path>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </div> 
                                     <div class="flex items-center justify-between p-4 border-b-2 border-gray-100">
                                        <p class="font-bold text-black text-md dark:text-white">
                                            Slack
                                        </p>
                                        <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 rounded">
                                            <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                                <g fill="none">
                                                    <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                                    </path>
                                                </g>
                                            </svg>
                                        </button>
                                    </div> 
                                    <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                                        <p class="flex items-center text-xs dark:text-white">
                                            <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path d="M12 5a8.5 8.5 0 1 1 0 17a8.5 8.5 0 0 1 0-17zm0 3a.75.75 0 0 0-.743.648l-.007.102v4.5l.007.102a.75.75 0 0 0 1.486 0l.007-.102v-4.5l-.007-.102A.75.75 0 0 0 12 8zm7.17-2.877l.082.061l1.149 1a.75.75 0 0 1-.904 1.193l-.081-.061l-1.149-1a.75.75 0 0 1 .903-1.193zM14.25 2.5a.75.75 0 0 1 .102 1.493L14.25 4h-4.5a.75.75 0 0 1-.102-1.493L9.75 2.5h4.5z" fill="currentColor">
                                                    </path>
                                                </g>
                                            </svg>
                                            International
                                        </p>
                                        <div class="flex items-center">
                                            <span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
                                                30 min
                                            </span>
                                            <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 rounded">
                                                <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                                    <g fill="none">
                                                        <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                                        </path>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
                                        <p class="flex items-center text-xs dark:text-white">
                                            <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path d="M12 5a8.5 8.5 0 1 1 0 17a8.5 8.5 0 0 1 0-17zm0 3a.75.75 0 0 0-.743.648l-.007.102v4.5l.007.102a.75.75 0 0 0 1.486 0l.007-.102v-4.5l-.007-.102A.75.75 0 0 0 12 8zm7.17-2.877l.082.061l1.149 1a.75.75 0 0 1-.904 1.193l-.081-.061l-1.149-1a.75.75 0 0 1 .903-1.193zM14.25 2.5a.75.75 0 0 1 .102 1.493L14.25 4h-4.5a.75.75 0 0 1-.102-1.493L9.75 2.5h4.5z" fill="currentColor">
                                                    </path>
                                                </g>
                                            </svg>
                                            Slack logo design
                                        </p>
                                        <div class="flex items-center">
                                            <span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
                                                30 min
                                            </span>
                                            <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 rounded">
                                                <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                                    <g fill="none">
                                                        <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                                        </path>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-between px-4 py-2 text-gray-600">
                                        <p class="flex items-center text-xs dark:text-white">
                                            <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 24 24">
                                                <g fill="none">
                                                    <path d="M12 5a8.5 8.5 0 1 1 0 17a8.5 8.5 0 0 1 0-17zm0 3a.75.75 0 0 0-.743.648l-.007.102v4.5l.007.102a.75.75 0 0 0 1.486 0l.007-.102v-4.5l-.007-.102A.75.75 0 0 0 12 8zm7.17-2.877l.082.061l1.149 1a.75.75 0 0 1-.904 1.193l-.081-.061l-1.149-1a.75.75 0 0 1 .903-1.193zM14.25 2.5a.75.75 0 0 1 .102 1.493L14.25 4h-4.5a.75.75 0 0 1-.102-1.493L9.75 2.5h4.5z" fill="currentColor">
                                                    </path>
                                                </g>
                                            </svg>
                                            Dahboard template
                                        </p>
                                        <div class="flex items-center">
                                            <span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
                                                30 min
                                            </span>
                                            <button class="p-1 mr-4 text-sm text-gray-400 border border-gray-400 rounded">
                                                <svg width="15" height="15" fill="currentColor" viewBox="0 0 20 20">
                                                    <g fill="none">
                                                        <path d="M17.222 8.685a1.5 1.5 0 0 1 0 2.628l-10 5.498A1.5 1.5 0 0 1 5 15.496V4.502a1.5 1.5 0 0 1 2.223-1.314l10 5.497z" fill="currentColor">
                                                        </path>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div class="w-full sm:w-1/2 xl:w-1/3">
                            <div class="mb-4">
                                <div class="p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-700">
                                    <div class="flex flex-wrap overflow-hidden">
                                        <div class="w-full rounded shadow-sm">
                                            <div class="flex items-center justify-between mb-4">
                                                <div class="text-xl font-bold text-left text-black dark:text-white">
                                                    Dec 2021
                                                </div>
                                                <div class="flex space-x-4">
                                                    <button class="p-2 text-white bg-blue-500 rounded-full">
                                                        <svg width="15" height="15" fill="currentColor" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z">
                                                            </path>
                                                        </svg>
                                                    </button>
                                                    <button class="p-2 text-white bg-blue-500 rounded-full">
                                                        <svg width="15" height="15" fill="currentColor" viewBox="0 0 24 24">
                                                            <path fill="currentColor" d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z">
                                                            </path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="-mx-2">
                                                <table class="w-full dark:text-white">
                                                    <tr>
                                                        <th class="px-2 py-3 md:px-3 ">
                                                            S
                                                        </th>
                                                        <th class="px-2 py-3 md:px-3 ">
                                                            M
                                                        </th>
                                                        <th class="px-2 py-3 md:px-3 ">
                                                            T
                                                        </th>
                                                        <th class="px-2 py-3 md:px-3 ">
                                                            W
                                                        </th>
                                                        <th class="px-2 py-3 md:px-3 ">
                                                            T
                                                        </th>
                                                        <th class="px-2 py-3 md:px-3 ">
                                                            F
                                                        </th>
                                                        <th class="px-2 py-3 md:px-3 ">
                                                            S
                                                        </th>
                                                    </tr>
                                                    <tr class="text-gray-400 dark:text-gray-500">
                                                        <td class="px-2 py-3 text-center text-gray-300 md:px-3 dark:text-gray-500">
                                                            25
                                                        </td>
                                                        <td class="px-2 py-3 text-center text-gray-300 md:px-3 dark:text-gray-500">
                                                            26
                                                        </td>
                                                        <td class="px-2 py-3 text-center text-gray-300 md:px-3 dark:text-gray-500">
                                                            27
                                                        </td>
                                                        <td class="px-2 py-3 text-center text-gray-300 md:px-3 dark:text-gray-500">
                                                            28
                                                        </td>
                                                        <td class="px-2 py-3 text-center text-gray-300 md:px-3 dark:text-gray-500">
                                                            29
                                                        </td>
                                                        <td class="px-2 py-3 text-center text-gray-300 md:px-3 dark:text-gray-500">
                                                            30
                                                        </td>
                                                        <td class="px-2 py-3 text-center text-gray-800 cursor-pointer md:px-3 hover:text-blue-500">
                                                            1
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            2
                                                        </td>
                                                        <td class="relative px-1 py-3 text-center cursor-pointer hover:text-blue-500">
                                                            3
                                                            <span class="absolute bottom-0 w-2 h-2 transform -translate-x-1/2 bg-blue-500 rounded-full left-1/2">
                                                            </span>
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            4
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            5
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            6
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            7
                                                        </td>
                                                        <td class="relative px-2 py-3 text-center cursor-pointer md:px-3 lg:px-3 hover:text-blue-500">
                                                            8
                                                            <span class="absolute bottom-0 w-2 h-2 transform -translate-x-1/2 bg-yellow-500 rounded-full left-1/2">
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            9
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            10
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            11
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            12
                                                        </td>
                                                        <td class="px-2 py-3 text-center text-white cursor-pointer md:px-3">
                                                            <span class="p-2 bg-blue-500 rounded-full">
                                                                13
                                                            </span>
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            14
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            15
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            16
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            17
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            18
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            19
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            20
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            21
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            22
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            23
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            24
                                                        </td>
                                                        <td class="relative px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            25
                                                            <span class="absolute bottom-0 w-2 h-2 transform -translate-x-1/2 bg-red-500 rounded-full left-1/2">
                                                            </span>
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            26
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            27
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            28
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            29
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            30
                                                        </td>
                                                        <td class="px-2 py-3 text-center cursor-pointer md:px-3 hover:text-blue-500">
                                                            31
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="w-full p-4 bg-white shadow-lg rounded-2xl dark:bg-gray-700">
                                    <p class="font-bold text-black text-md dark:text-white">
                                        Messages
                                    </p>
                                    <ul>
                                        <li class="flex items-center my-6 space-x-2">
                                            <a href="#" class="relative block">
                                                <img alt="profil" src="/images/person/1.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                                            </a>
                                            <div class="flex flex-col">
                                                <span class="ml-2 text-sm font-semibold text-gray-900 dark:text-white">
                                                    Charlie Rabiller
                                                </span>
                                                <span class="ml-2 text-sm text-gray-400 dark:text-gray-300">
                                                    Hey John ! Do you read the NextJS doc ?
                                                </span>
                                            </div>
                                        </li>
                                        <li class="flex items-center my-6 space-x-2">
                                            <a href="#" class="relative block">
                                                <img alt="profil" src="/images/person/5.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                                            </a>
                                            <div class="flex flex-col">
                                                <span class="ml-2 text-sm font-semibold text-gray-900 dark:text-white">
                                                    Marie Lou
                                                </span>
                                                <span class="ml-2 text-sm text-gray-400 dark:text-gray-300">
                                                    No I think the dog is better...
                                                </span>
                                            </div>
                                        </li>
                                        <li class="flex items-center my-6 space-x-2">
                                            <a href="#" class="relative block">
                                                <img alt="profil" src="/images/person/6.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                                            </a>
                                            <div class="flex flex-col">
                                                <span class="ml-2 text-sm font-semibold text-gray-900 dark:text-white">
                                                    Ivan Buck
                                                </span>
                                                <span class="ml-2 text-sm text-gray-400 dark:text-gray-300">
                                                    Seriously ? haha Bob is not a child !
                                                </span>
                                            </div>
                                        </li>
                                        <li class="flex items-center my-6 space-x-2">
                                            <a href="#" class="relative block">
                                                <img alt="profil" src="/images/person/7.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                                            </a>
                                            <div class="flex flex-col">
                                                <span class="ml-2 text-sm font-semibold text-gray-900 dark:text-white">
                                                    Marina Farga
                                                </span>
                                                <span class="ml-2 text-sm text-gray-400 dark:text-gray-300">
                                                    Do you need that design ?
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </main>


    



        </div>
     

    </div>



  

{/*    
<div class="flex flex-col ">

<img src="/wall.png" class="absolute h-1/2 w-full md:object-cover object-fit overflow-hidden  "/>

<div>
<img 
  src={data.url 
    // ? data.url[0].replace('/upload/', '/upload/e_bgremoval/') 
    ? data.url[0]
    : "https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph"} 
  className="relative h-60 w-11/12 ml-4 md:h-96 md:w-1/2 object-center md:ml-80 md:mt-24 mt-32 rounded-lg" 
/>
</div>













<div class="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8 mt-2 mb-4">
    <div class="max-w-lg mx-auto overflow-hidden rounded-lg shadow-lg pricing-box lg:max-w-none lg:flex">
        <div class="px-6 py-8 bg-gray-50 dark:bg-gray-800 lg:flex-shrink-1 lg:p-12">
            <h3 class="text-2xl font-extrabold leading-8 text-gray-900 sm:text-3xl sm:leading-9 dark:text-white">
                {data?data.title:""}
            </h3>
            <p class="mt-6 text-base leading-6 text-gray-500 dark:text-gray-200">
                {data?data.body:""}
            </p>
            <div class="mt-8">
                <div class="flex items-center">
                    <h4 class="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-indigo-600 uppercase bg-gray-50 dark:bg-gray-800">
                        What&#x27;s included
                    </h4>
                    <div class="flex-1 border-t-2 border-gray-200">
                    </div>
                </div>
                <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            All illimited components
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Own custom Tailwind styles
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Unlimited Templates
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Free premium dashboard
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Best ranking
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            Prenium svg
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                                <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            My 
                        </p>
                    </li>
                </ul>
            </div>
            <div class="mt-8">
                <div class="flex items-center">
                    <h4 class="flex-shrink-0 pr-4 text-sm font-semibold leading-5 tracking-wider text-indigo-600 uppercase bg-gray-50 dark:bg-gray-800">
                        &amp; What&#x27;s not
                    </h4>
                </div>
                <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="w-6 h-6 mr-2" fill="red" viewBox="0 0 1792 1792">
                                <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            No Contracts. No monthly, setup, or additional payment processor fees
                        </p>
                    </li>
                    <li class="flex items-start lg:col-span-1">
                        <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="w-6 h-6 mr-2" fill="red" viewBox="0 0 1792 1792">
                                <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                                </path>
                            </svg>
                        </div>
                        <p class="ml-3 text-sm leading-5 text-gray-700 dark:text-gray-200">
                            No 2-week on-boarding, it takes 20 minutes!
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="px-6 py-8 text-center bg-gray-50 dark:bg-gray-700 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
            <p class="text-lg font-bold leading-6 text-gray-900 dark:text-white">
                Fee
            </p>
            <div class="flex items-center justify-center mt-4 text-5xl font-extrabold leading-none text-gray-900 dark:text-white">
                <span>
                    ${data.fee}
                </span>
            </div>
            <p class="mt-4 text-sm leading-5">
                <span class="block font-medium text-gray-500 dark:text-gray-400">
                    Card payments:
                </span>
                <span class="inline-block font-medium text-gray-500  dark:text-gray-400">
                    2.9% + 20p per transaction
                </span>
            </p>
            <div class="mt-6">
                <div class="rounded-md shadow">
                    <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                    onClick={()=>{startChat()}}
                    >
                        Enroll Now
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>



{
policy? 
(
<>
<div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>
 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setPolicy(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
  
  <h1 className='text-lg text-center'>
  Refund and Cancellation Policy

  </h1>


<p className='text-gray-400 text-sm m-2'>
Thank you for purchasing tickets through B5 Events. Please review our refund and cancellation policy carefully:

No Refunds for Missed Events: We do not offer refunds if you do not attend the event. All ticket sales are final.

Ticket Delivery Issues: If you do not receive your tickets after purchase, please contact us immediately. Send an email to info@b5events.com with your order details, and we will assist in resolving the issue.

No Refund for Non-Receipt of Tickets Without Prior Contact: Failure to receive tickets without informing us will not be eligible for a refund. It is your responsibility to notify us if there is an issue.

Event Cancellations: In the rare event of a cancellation by B5 Events, a full refund will be provided.

By making a purchase, you agree to these terms.

</p>







 

</div>


</div>
</>
)


:null

}


</div>
         */}














<Footer page="events"></Footer>
</main>







</>

  


    );
}

export default SingleDzongkhaDash;