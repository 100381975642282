
import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link} from 'react-router-dom'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ChatIcon from '@mui/icons-material/Chat';

// import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@mui/icons-material/GetApp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Footer from "./Footer"
import Nav from "./Nav"




export default function Dash_std() {



      const [dzongkha,setDzongkha] = useState([])
        const [academics,setAcademic] = useState([])
        const [skills,setSkills] = useState([])
        // const history =useNavigate();
        const [email,setEmail] =  useState("");
        const [password,setPassword] =  useState("");
        // const navigate= useNavigate()
        const [data,setData]= useState([])
        const [date,setDate]= useState(new Date())
        const [showData,setShowData]= useState([])
        const [search,setSearch] = useState("")
      
        useEffect(  ()=>{
          // AOS.init();
          // AOS.refresh()
         fetch('/getMyacademicsessions', {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt")
            }
          })
            .then(res => res.json())
            .then(result => {
              setAcademic(result.posts);
            })
            .catch(err => console.error(err));
      
      
      
          fetch('/getMydzongkhasessions',{
            headers:{
                Authorization: "Bearer " +localStorage.getItem("jwt")
                
            }
        }).then(res=>res.json())
        .then(result=>{
          
            setDzongkha(result.posts.reverse())
        })
      
        },[])
      
      
      


  return (
    <div>     
    {/* Nav */}
    <Nav/>
    {/* end Nav */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Welcome To RIG.jr</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Replace with your content */}
          {/* Avator of the User */}
            <a href="#" class="block relative">
                <img alt="profil"                                 src="https://img.freepik.com/free-vector/academic-excellence-illustration_24877-52355.jpg?t=st=1740417161~exp=1740420761~hmac=a9589bc5bf53dea33198d64367fe39267154153e632c75f680b6d56fd54eed77&w=1380"
 class="mx-auto object-cover rounded-full h-52 w-52"/>
            </a>
           {/*Avator ends */}
           {/* Features Regarding studing*/}


         
           <div class="flex items-center justify-center  bg-white">
    <div class="flex max-w-md overflow-hidden bg-white rounded-lg shadow-lg">
        <div class="w-1/3">
            <img class="h-full w-full object-cover" src='https://res.cloudinary.com/dvodoygwr/image/upload/v1741600716/pdf/uploadimage-1741600716151.png.png' />
        </div>
        <div class="w-2/3 p-4">
            <h1 class="text-2xl font-bold text-gray-900">
                Free Trial Classes for Dzongkha 
            </h1>
            <p class="mt-2 text-sm text-gray-600">
                You can check out our free trial classes for class 8 Dzongkha Thursday April 3rd, 6 PM - 7 PM
            </p>
        
            <div class="flex justify-between mt-3 items-center">
                <h1 class="text-xl font-bold text-gray-700">
                    Free (April 3rd 6 PM) 
                </h1>
                <a href="https://meet.google.com/vvu-gxiw-wpi" class="px-3 py-2 text-xs font-bold text-white uppercase bg-gray-800 rounded">
                    Join Now
                </a>
            </div>
        </div>
    </div>
</div>


           
           <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900 text-center">Lets Start Learning</h1>
           </div>


           
           <div class="grid md:grid-cols-3 grid-cols-1 gap-4"> 


{/* <div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<p class="text-xl text-center font-medium text-gray-800  mb-2">
Coaching
</p>

<Link to='/chat'>

<div class="flex items-center justify-between p-3 mb-2 bg-blue-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />  <line x1="8" y1="9" x2="16" y2="9" />  <line x1="8" y1="13" x2="14" y2="13" /></svg>             
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    1:1 With Teacher
</p>

</div>
</div>
</Link>

<Link to='/subject'>                   

<div class="flex items-center justify-between p-3 mb-2 bg-blue-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="10" y1="14" x2="21" y2="3" />  <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" /></svg>        </span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    Post Doubts
</p>

</div>
</div>
</Link>


<Link to="/searchTeacher">

<div class="flex items-center justify-between p-3 mb-2 bg-blue-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg class="h-8 w-8 text-blue-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
</svg>

</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    Teachers
</p>

</div>
</div>
</Link>


</div> */}
<div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<p class="text-xl text-center font-medium text-gray-800  mb-2">
Up Skill
</p>

<Link to={"/coursesessions"}>
<div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
Search Courses 
</p>

</div>
</div>
</Link>
<Link to={"/mycoursesessions"}>

<div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    My Courses 
</p>

</div>
</div>

</Link>
{/* <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    Helfest tickets
</p>

</div>
</div> */}
</div>
<div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<p class="text-xl text-center font-medium text-gray-800  mb-2">
Dzongkha Session
</p>

<Link to={"/dzongkhasessions"}>
<div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
Search Dzongkha Sessions 
</p>

</div>
</div>
</Link>
<Link to={"/mydzongkhasessions"}>

<div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    My Dzongkha Sessions 
</p>

</div>
</div>

</Link>
{/* <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    Helfest tickets
</p>

</div>
</div> */}
</div>
<div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<p class="text-xl text-center font-medium text-gray-800  mb-2">
Rig Jr Academic
</p>

<Link to={"/academicsessions"}>
<div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1520 1216q0-40-28-68l-208-208q-28-28-68-28-42 0-72 32 3 3 19 18.5t21.5 21.5 15 19 13 25.5 3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13-19-15-21.5-21.5-18.5-19q-33 31-33 73 0 40 28 68l206 207q27 27 68 27 40 0 68-26l147-146q28-28 28-67zm-703-705q0-40-28-68l-206-207q-28-28-68-28-39 0-68 27l-147 146q-28 28-28 67 0 40 28 68l208 208q27 27 68 27 42 0 72-31-3-3-19-18.5t-21.5-21.5-15-19-13-25.5-3.5-27.5q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13 19 15 21.5 21.5 18.5 19q33-31 33-73zm895 705q0 120-85 203l-147 146q-83 83-203 83-121 0-204-85l-206-207q-83-83-83-203 0-123 88-209l-88-88q-86 88-208 88-120 0-204-84l-208-208q-84-84-84-204t85-203l147-146q83-83 203-83 121 0 204 85l206 207q83 83 83 203 0 123-88 209l88 88q86-88 208-88 120 0 204 84l208 208q84 84 84 204z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
Search Academic Courses 
</p>

</div>
</div>
</Link>
<Link to={"/myacademicsessions"}>

<div class="flex items-center justify-between p-3 mb-2 bg-violet-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M1596 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1600q0-40 28-68t68-28h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528v-1024h-416q-40 0-68-28t-28-68v-416h-768v1536h1280zm-128-448v320h-1024v-192l192-192 128 128 384-384zm-832-192q-80 0-136-56t-56-136 56-136 136-56 136 56 56 136-56 136-136 56z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    My Academic Sessions 
</p>

</div>
</div>

</Link>
{/* <div class="flex items-center justify-between p-3 mb-2 bg-yellow-100 rounded">
<span class="p-2 bg-white rounded-lg">
<svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
    <path d="M960 256q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm832 928v352q0 22-20 30-8 2-12 2-12 0-23-9l-93-93q-119 143-318.5 226.5t-429.5 83.5-429.5-83.5-318.5-226.5l-93 93q-9 9-23 9-4 0-12-2-20-8-20-30v-352q0-14 9-23t23-9h352q22 0 30 20 8 19-7 35l-100 100q67 91 189.5 153.5t271.5 82.5v-647h-192q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h192v-163q-58-34-93-92.5t-35-128.5q0-106 75-181t181-75 181 75 75 181q0 70-35 128.5t-93 92.5v163h192q26 0 45 19t19 45v128q0 26-19 45t-45 19h-192v647q149-20 271.5-82.5t189.5-153.5l-100-100q-15-16-7-35 8-20 30-20h352q14 0 23 9t9 23z">
    </path>
</svg>
</span>
<div class="flex items-center justify-between w-full ml-2">
<p>
    Helfest tickets
</p>

</div>
</div> */}
</div>
{/* <div class="relative w-full p-4 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 ">
<p class="text-xl text-center font-medium text-gray-800  mb-2">
Rig Jr Academy
</p>

<h1 class="uppercase text-center mt-24 text-3xl"> Coming Soon </h1>
</div> */}



</div>

           <div className="max-w-7xl mx-auto pt-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900 text-center">My Enrolled Classes </h1>
           </div>




           <div  class="w-full  bg-white">
   

   <div class="md:px-6 md:py-6   md:rounded-lg md:bg-purple-50 md:shadow-lg">
   <div class="flex flex-col md:flex-row md:items-end justify-between mb-12 header">
   <div class="title">
       <p class="mb-4 text-4xl font-bold text-gray-800">
           My Academic Courses
       </p>
     
   </div>

   </div>
   <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 place-items-center">

{academics.slice(0, 4).map(item=>{

const itemDateObj = new Date(item.date);

itemDateObj.setHours(0, 0, 0, 0);
date.setHours(0, 0, 0, 0);



return(



<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90  w-80">
<a href={`/prejoiningacademic/${item._id}`} class="block w-full h-full">
   <img alt="blog photo" src={item.url[0]} class="object-cover w-full max-h-40"/>
   <div class="w-full p-4 bg-white dark:bg-gray-800">
       <p class="font-medium text-indigo-500 text-md">
       </p>
       <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
           {item.title}
       </p>
       <p class="font-light text-gray-400 dark:text-gray-300 text-md">
           JOIN NOW        <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="23 7 16 12 23 17 23 7" />  <rect x="1" y="5" width="15" height="14" rx="2" ry="2" /></svg>

                    </p>
   </div>
</a>
</div>

   

)

})}





   </div>
    {/* <div class="mt-6  flex justify-center items-center">
<button onClick={()=>history('/findacademiccourses')} type="button" class="py-2 md:w-1/3 w-full h-14 px-4 flex justify-center items-center border-purple-300  bg-none hover:bg-purple-500 focus:ring-red-500 focus:ring-offset-red-200 text-purple-500 hover:text-white  transition ease-in duration-200 text-center text-base  border-2 border-purple-500 font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">

Find more Academic Courses
<svg class="h-8 w-8 "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="13 17 18 12 13 7" />  <polyline points="6 17 11 12 6 7" /></svg>
</button>
</div> */}

    </div>

  
<div class="flex flex-col md:flex-row md:items-end justify-between mb-6 mt-6 header">
  
   </div>

   <div class="md:px-6 md:py-6   md:rounded-lg md:bg-purple-50 md:shadow-lg">
   <div class="title">
       <p class="mb-12 text-4xl font-bold text-gray-800">
           My Dzongkha Courses
       </p>
     
   </div>

   <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 place-items-center">

{dzongkha.slice(0, 4).map(item=>{
return(


<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90  w-80">
<a href={`/prejoiningdzongkha/${item._id}`} class="block w-full h-full">
   <img alt="blog photo" src={item.url[0]} class="object-cover w-full max-h-40"/>
   <div class="w-full p-4 bg-white dark:bg-gray-800">
       <p class="font-medium text-indigo-500 text-md">
       </p>
       <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
           {item.title}
       </p>
       <p class="font-light text-gray-400 dark:text-gray-300 text-md">
           {
             item.body
           }            </p>
   </div>
</a>
</div>

     


)

})}





   </div>
    {/* <div class="mt-6  flex justify-center items-center">
<button onClick={()=>history('/finddzongkhacourses')} type="button" class="py-2 md:w-1/3 w-full h-14 px-4 flex justify-center items-center border-purple-300  bg-none hover:bg-purple-500 focus:ring-red-500 focus:ring-offset-red-200 text-purple-500 hover:text-white  transition ease-in duration-200 text-center text-base  border-2 border-purple-500 font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">

Find More Dzongkha Courses
<svg class="h-8 w-8 "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="13 17 18 12 13 7" />  <polyline points="6 17 11 12 6 7" /></svg>
</button>
</div> */}

    </div>

  




</div>

           




<div class="p-4 mt-4">
    <p class="text-3xl font-bold text-center text-gray-800">
        Professional Tutors
    </p>
    <p class="mb-32 text-xl font-normal text-center text-gray-500">
        Meet our Experts
    </p>
    <div class="flex flex-col items-center space-y-24 md:space-y-0 md:flex-row justify evenly">
        <div class="relative p-4">
            <div class="absolute mb-4 text-center transform translate-x-1/2 -top-16 right-1/2">
                <a href="#" class="relative block">
                    <img alt="profil" src="/bedu.jpeg" class="mx-auto object-cover rounded-lg h-40 w-40  border-2 border-white dark:border-gray-800"/>
                </a>
            </div>
            <div class="px-8 py-4 pt-24 bg-white rounded-lg shadow dark:bg-gray-800">
                <div class="text-center">
                    <p class="text-2xl text-gray-800 dark:text-white">
                   Bedanidhi Bhandari                    </p>
                    <p class="text-xl font-light text-gray-500 dark:text-gray-200">
                        Physics and Maths Teacher
                    </p>
                    <p class="py-4 mx-auto font-light text-gray-500 text-md w-60 dark:text-gray-400">
                    Work Experience: 21 years
Assistant Engineer at BICMA
Network Engineer at Bhutan Telecom
Senior Manager at Tashi Incomm Pvt Limited
Physics and Mathematics Teacher at Nima Higher School
Certified in Cisco CCNA, CCNP and JUNOS network                    </p>
                </div>
                {/* <div class="flex items-center justify-between w-40 pt-8 mx-auto text-gray-500 border-t border-gray-200">
                    <a href="#">
                        <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 dark:hover:text-white" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                            </path>
                        </svg>
                    </a>
                    <a href="#">
                        <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 dark:hover:text-white" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                            </path>
                        </svg>
                    </a>
                    <a href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 dark:hover:text-white" viewBox="0 0 1792 1792">
                            <path d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5q0 251-146.5 451.5t-378.5 277.5q-27 5-40-7t-13-30q0-3 .5-76.5t.5-134.5q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-119-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-85-13.5q-45 113-8 204-79 87-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-39 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 88.5t.5 54.5q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103zm-477 1103q3-7-7-12-10-3-13 2-3 7 7 12 9 6 13-2zm31 34q7-5-2-16-10-9-16-3-7 5 2 16 10 10 16 3zm30 45q9-7 0-19-8-13-17-6-9 5 0 18t17 7zm42 42q8-8-4-19-12-12-20-3-9 8 4 19 12 12 20 3zm57 25q3-11-13-16-15-4-19 7t13 15q15 6 19-6zm63 5q0-13-17-11-16 0-16 11 0 13 17 11 16 0 16-11zm58-10q-2-11-18-9-16 3-14 15t18 8 14-14z">
                            </path>
                        </svg>
                    </a>
                    <a href="#">
                        <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 dark:hover:text-white" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                            </path>
                        </svg>
                    </a>
                    <a href="#">
                        <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 dark:hover:text-white" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1551 1476q15-6 26-3t11 17.5-15 33.5q-13 16-44 43.5t-95.5 68-141 74-188 58-229.5 24.5q-119 0-238-31t-209-76.5-172.5-104-132.5-105-84-87.5q-8-9-10-16.5t1-12 8-7 11.5-2 11.5 4.5q192 117 300 166 389 176 799 90 190-40 391-135zm207-115q11 16 2.5 69.5t-28.5 102.5q-34 83-85 124-17 14-26 9t0-24q21-45 44.5-121.5t6.5-98.5q-5-7-15.5-11.5t-27-6-29.5-2.5-35 0-31.5 2-31 3-22.5 2q-6 1-13 1.5t-11 1-8.5 1-7 .5h-10l-3-.5-2-1.5-1.5-3q-6-16 47-40t103-30q46-7 108-1t76 24zm-394-443q0 31 13.5 64t32 58 37.5 46 33 32l13 11-227 224q-40-37-79-75.5t-58-58.5l-19-20q-11-11-25-33-38 59-97.5 102.5t-127.5 63.5-140 23-137.5-21-117.5-65.5-83-113-31-162.5q0-84 28-154t72-116.5 106.5-83 122.5-57 130-34.5 119.5-18.5 99.5-6.5v-127q0-65-21-97-34-53-121-53-6 0-16.5 1t-40.5 12-56 29.5-56 59.5-48 96l-294-27q0-60 22-119t67-113 108-95 151.5-65.5 190.5-24.5q100 0 181 25t129.5 61.5 81 83 45 86 12.5 73.5v589zm-672 21q0 86 70 133 66 44 139 22 84-25 114-123 14-45 14-101v-162q-59 2-111 12t-106.5 33.5-87 71-32.5 114.5z">
                            </path>
                        </svg>
                    </a>
                </div> */}
            </div>
        </div>
     
    </div>
</div>

         
           
        </div>
      </main>
       {/* Footer */}
       <Footer/>
       {/* end Footer */}
    </div>
  )
}
