import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate,useParams} from 'react-router-dom'
import { UploadIcon } from '@heroicons/react/outline';

import Nav_men from "./Nav_men"
import Nav_admin from './nav_admin';


function EditCourseAcademicAdmin() {

          const history = useNavigate()
          const [title,setTitle] = useState("")
          const [body,setBody] = useState("")
          const [url,setUrl] = useState([])
          const[progress,setProgress] = useState(false)
          const [subject,setSubject] = useState("")
          const [fee,setFee] = useState(0)
          const [category,setCategory] = useState("Video")
        const {dzongkhaid} = useParams()

     const [lesson,setLesson] = useState([])
          const [lessonTitle,setLessonTitle] = useState("")
          const [lessonDescription,setLessonDescription] = useState("")
          const [editIndex, setEditIndex] = useState(null); // Track the index of the lesson being edited

          const [schedule, setSchedule] = useState([]);
          const [scheduleDay, setScheduleDay] = useState("");
          const [scheduleHr, setScheduleHr] = useState("");
          
useEffect(()=>{

    fetch(`/singleacademicsessions/${dzongkhaid}`,{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{
    //   setData(result.post)
      setTitle(result.post.title)  
      setBody(result.post.body)  
      setFee(result.post.fee)  
      setLesson(result.post.lesson)  
      setUrl(result.post.url)  
      setSchedule(result.post.schedule)  
    //   console.log("POH",result.post)
    })
     

},[])
       

          const PostData=()=>{
    
    fetch(`/supereditacademicsessions/${dzongkhaid}`,{
      method:"put",
      headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({

        title,
        body,
        fee,
        lesson,
        url,
        schedule
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
      if(data.error){
          console.log("error")
      }
      else{
          history(`/editacademicrecordadmin/${dzongkhaid}`)
      }
  })
  .catch(err=>{
      console.log(err)
  })

   }


//    const uploadppt=({target:{files}})=>{
//     setProgress(true)
   
//     let data = new FormData()

//     for(const file of files){

//    data.append('ppt', file)
//    data.append('name',file.name)
// }
//        fetch("/ppt", {

//          method:"post",
//          body: data
//      })
//      .then(res=>res.json())
//      .then(data=>{

//          for(const path of data.message){
//           setUrl(path.path)
//           console.log(path.path)
//          }

       
//      })
//      .catch(err=>{
//          console.log(err)
//      })

// }


   const uploadvid=({target:{files}})=>{

   
    let data = new FormData()

    for(const file of files){

   data.append('video', file)
   data.append('name',file.name)
}
       fetch("/video", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

         for(const path of data.message){
          setUrl(path.path)
          console.log(path.path)
         }

       
     })
     .catch(err=>{
         console.log(err)
     })

}

  
const uploadppt=({target:{files}})=>{

  setProgress(true)

 let data = new FormData()

 for(const file of files){

data.append('uploadimage', file)
data.append('name',file.name)
}
    fetch("/uploadimage", {

      method:"post",
      body: data
  })
  .then(res=>res.json())
  .then(data=>{

        setUrl(old=>[...old, data.url])
        setProgress(false)

   

    
  })
  .catch(err=>{
      console.log(err)
  })

}

   
const removePicture = (index,amt) => {
    
    
  setUrl([
    ...url.slice(0, index),
    ...url.slice(index + 1, url.length)
  ]);


  // setCreditItem([
  //   ...creditItem.slice(0, index),
  //   ...creditItem.slice(index + 1, creditItem.length)
  // ]);

  // setAmount(prevCount => prevCount - amt)

}



const postLesson = (lessonTitle,lessonDescription)=>{
  setLesson(item=>[...item, {title:lessonTitle,description:lessonDescription}])
   setLessonTitle("")
   setLessonDescription("")
}


// const removeLesson = (index) => {
    
    
//   setLesson([
//     ...lesson.slice(0, index),
//     ...lesson.slice(index + 1, setLesson.length)
//   ]);



// }



  // Function to add or update a lesson
  const handleSaveLesson = () => {
    if (!lessonTitle.trim() || !lessonDescription.trim()) return; // Prevent empty values

    if (editIndex !== null) {
      // Update existing lesson
      const updatedLesson = lesson.map((lesson, index) =>
        index === editIndex ? { title: lessonTitle, description: lessonDescription } : lesson
      );
      setLesson(updatedLesson);
      setEditIndex(null); // Reset edit mode
    } else {
      // Add new lesson
      setLesson([...lesson, { title: lessonTitle, description: lessonDescription }]);
    }

    // Reset input fields
    setLessonTitle("");
    setLessonDescription("");
  };

  // Function to edit a lesson
  const handleEditLesson = (index) => {
    setLessonTitle(lesson[index].title);
    setLessonDescription(lesson[index].description);
    setEditIndex(index);
  };

  // Function to remove a lesson
  const removeLesson = (index) => {
    setLesson(lesson.filter((_, i) => i !== index));
  };





  const days = [
    "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
  ];
  
  const timeSlots = [
    "6AM - 7AM", "7AM - 8AM", "8AM - 9AM", "9AM - 10AM", "10AM - 11AM", "11AM - 12PM", 
    "12PM - 1PM", "1PM - 2PM", "2PM - 3PM", "3PM - 4PM", "4PM - 5PM", "5PM - 6PM", 
    "6PM - 7PM", "7PM - 8PM", "8PM - 9PM", "9PM - 10PM"
  ];
  
  const addSchedule = () => {
    if (scheduleDay && scheduleHr) {
      setSchedule([...schedule, { day: scheduleDay, hr: scheduleHr }]);
      setScheduleDay("");
      setScheduleHr("");
    }
  };
  
  const removeSchedule = (index) => {
    setSchedule(schedule.filter((_, i) => i !== index));
  };
  
    return (
        <div>

<main class="relative h-screen overflow-hidden bg-gray-100 dark:bg-gray-800">
    <div class="flex items-start justify-between">
        <div class="relative hidden h-screen shadow-lg lg:block w-80">
            <div class="h-full bg-white dark:bg-gray-700">
                <div class="flex items-center  pt-6 ml-8">
                    <p class="text-xl text-center font-bold dark:text-white">
                        Rig Jr.
                    </p>
                </div>
               <Nav_admin></Nav_admin>
            </div>
        </div>
        <div class="flex flex-col w-full md:space-y-4">
          
            <div class="h-screen px-4 pb-24 overflow-auto md:px-6">
            <div className="   justify-center bg-cover bg-back">

<div class="container max-w-2xl mx-auto mt-4 shadow-md md:w-3/4">
<div class="p-4 border-t-2 border-indigo-400 rounded-lg bg-gray-100/5 ">

</div>
<div class="space-y-6 bg-white">
<div class="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
      <h2 class="max-w-sm mx-auto md:w-1/3">
          Basic Details of Course
      </h2>
      <div class="max-w-sm mx-auto space-y-5 md:w-2/3">
          <div>
            Subject Name
              <div class=" relative ">
                  <input type="text" id="user-info-name" class=" rounded-lg border-1 flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Name"
                  value={title}
                  onChange={(e)=>{setTitle(e.target.value)}}
                  />
                  </div>
              </div>
          <div>
            Short Description
              <div class=" relative ">
                  <textarea type="text" id="user-info-name" class=" rounded-lg border-1 flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Short Description of Course"
                   value={body}
                   onChange={(e)=>{setBody(e.target.value)}}
                  />
                  </div>
              </div>
              {/* <div>
                  <div class=" relative ">
                      <input type="text" id="user-info-phone" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Phone number"/>
                      </div>
                  </div> */}
              </div>
          </div>
  <hr/>
  <h1 className='text-center'>Add the List of chapters or topics in the course</h1>

<div class="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">

  <div class=" mx-auto md:w-2/3">
  Chapter / Topic Name
      <div class=" relative ">
          <input type="text" id="user-info-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Enter Name of Chapter/ Topic"
           value={lessonTitle}
           onChange={(e)=>{setLessonTitle(e.target.value)}}
          />
          </div>
      </div>
  <div class=" mx-auto md:w-2/3">
  Description
      <div class=" relative ">
          <textarea type="text" id="user-info-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Short Description of Chapter"
          value={lessonDescription}
          onChange={(e)=>{setLessonDescription(e.target.value)}}
          >
          </textarea>
          </div>
      </div>
  </div>
  <div class="w-full px-4 pb-4 ml-auto text-gray-500 md:w-1/3">
  <button
className="py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white w-full rounded-lg transition ease-in duration-200"
onClick={handleSaveLesson}
>
{editIndex !== null ? "Update Lesson" : "Add +"}
</button>
              </div>


              <div class="container max-w-3xl px-4 mx-auto sm:px-8">
<div class="py-8">
<div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
<div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
  <table class="min-w-full leading-normal">
      <thead>
          <tr>
              <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                  Topic
              </th>
             
            
              <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                  Description
              </th>
              <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                  Remove
              </th>
          </tr>
      </thead>
      <tbody>

      {lesson.map((item, index) => (
<tr key={index} className="border-b border-gray-200 bg-white">
<td className="px-5 py-3">{item.title}</td>
<td className="px-5 py-3">{item.description}</td>
<td className="px-5 py-3 flex space-x-3">
  {/* Edit Button */}
  <button
    className="px-3 py-1 bg-yellow-500 text-white rounded-lg"
    onClick={() => handleEditLesson(index)}
  >
    Edit
  </button>

  {/* Delete Button */}
  <button
    className="px-3 py-1 bg-red-500 text-white rounded-lg"
    onClick={() => removeLesson(index)}
  >
    Delete
  </button>
</td>
</tr>
))}
      
      </tbody>
  </table>
</div>
</div>
</div>
</div>

              <hr/>
  {/* <div class="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">

      <div class="max-w-sm mx-auto space-y-5 md:w-2/3">
          <div>
              <div class=" relative ">
                  <input type="text" id="user-info-name" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-black placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Name"/>
                  </div>
              </div>
              <div>
                  <div class=" relative ">
                      <input type="text" id="user-info-phone" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Phone number"/>
                      </div>
                  </div>
              </div>
          </div>
          <div class="w-full px-4 pb-4 ml-auto text-gray-500 md:w-1/3">
                  <button type="submit" class="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                      Save
                  </button>
              </div> */}
          <div class="items-center w-full p-8 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
          <div className="w-full mb-5 inline-flex justify-center p-2 md:col-span-1">
          <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
<svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
<path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
</svg>
<span className="mt-2 text-base leading-normal p-2">Upload Thumbnail Image</span>
<input type='file' className="hidden" 
onChange={uploadppt}
/>

</label>






                 </div>          
{progress?
  <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
:<></>}

    <div class="container flex flex-col items-center justify-center w-full mx-auto ">
<ul class="grid  grid-cols-1">

{url?url.map((item,index)=>{

return( <li class="flex flex-row mb-2 ">
<div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
    <a href="#" class="relative block">
        <img alt="profil" src={item} class="mx-auto object-cover rounded-sm h-48 w-48 "/>
    </a>



<button class="flex justify-end w-24 text-right"
onClick={()=>{removePicture(index)}}
>
<svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
</button>
</div>
</li>)

}):<></>}



</ul>
</div>
              </div>
              <hr/>

              <div class="items-center w-full  space-y-4 text-gray-500 ">
            
            <div className=" p-8 bg-white  rounded-lg">
<h2 className="text-lg font-semibold mb-4">Select Day & Time</h2>

<div className="mb-4">
<label className="block text-sm font-medium mb-1">Day</label>
<select
value={scheduleDay}
onChange={(e) => setScheduleDay(e.target.value)}
className="w-full p-2 border rounded"
>
<option value="">Select a day</option>
{days.map((day, index) => (
<option key={index} value={day}>{day}</option>
))}
</select>
</div>

<div className="mb-4">
<label className="block text-sm font-medium mb-1">Time Slot</label>
<input
value={scheduleHr}
onChange={(e) => setScheduleHr(e.target.value)}
className="w-full p-2 border rounded"
>
{/* <option value="">Select a time</option>
{timeSlots.map((slot, index) => (
<option key={index} value={slot}>{slot}</option>
))} */}
</input>
</div>
<div class="w-full px-4 pb-4 ml-auto text-gray-500 md:w-1/3">

<button
onClick={addSchedule}
className="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
>

Add Schedule +
</button>
</div>
<div className="mt-4">
<h3 className="text-lg font-semibold">Scheduled Times</h3>
<ul className="mt-2">
{schedule.map((item, index) => (
<li key={index} className="border p-2 rounded mt-1 flex justify-between items-center">
  <span>{item.day}, {item.hr}</span>
  <button 
    onClick={() => removeSchedule(index)}
    className="bg-red-500 text-white p-1 ml-2 rounded hover:bg-red-600"
  >
    Delete
  </button>
</li>
))}
</ul>
</div>
</div>

                </div>
                <hr/>
              <div class="w-full px-4 pb-4 ml-auto text-gray-500 md:w-1/3">
                  <button type="submit" class="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                  
                  onClick={()=>PostData()}

                  >
                      Save
                  </button>
              </div>
          </div>
      </div>



</div>
                
                
               
                
            </div>
        </div>
    </div>
</main>
            
        </div>
    )
}

export default EditCourseAcademicAdmin
