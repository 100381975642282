import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate} from 'react-router-dom'
import Nav from "./Nav"
import Footer from "./Footer"
import SubjectsCards from './SubjectsCards'
import SearchIcon from '@mui/icons-material/Search';
import {UserContex} from '../App'
import Nav_admin from './nav_admin';


function SuperStudentsList() {

  const searchModal = useRef(null)
  const [search,setSearch] = useState('')
  const [userDetails, setUserDetails] = useState([])
  const [data, setData] = useState([])
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  useEffect(() => {
    // const user = localStorage.getItem('user')?.replace(/"/g, ''); // Remove quotes from localStorage value
  var user
    fetch('/supergetstudents', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(res => res.json())
      .then(result => {
      
  setData(result.students)
       
        // Function to clean string by removing non-printable characters and trimming it
      
       
      })
      .catch(error => console.error("Error fetching sessions:", error));
  }, []);
  
  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-students',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.user)
      })


  }
    


    return (
        <div>



<main class="relative h-screen overflow-hidden bg-gray-100 dark:bg-gray-800">
    <div class="flex items-start justify-between">
        <div class="relative hidden h-screen shadow-lg lg:block w-80">
            <div class="h-full bg-white dark:bg-gray-700">
                <div class="flex items-center  pt-6 ml-8">
                    <p class="text-xl text-center font-bold dark:text-white">
                        Rig Jr.
                    </p>
                </div>
               <Nav_admin/>
            </div>
        </div>
        <div class="flex flex-col w-full md:space-y-4">
          
            <div class="h-screen px-4 pb-24 overflow-auto md:px-6">
               
                
                <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Search For Students</h1>
          <div class="flex relative ">
          <span class="mt-2 inline-flex  items-center px-3 text-sm">
            <SearchIcon/>        
    </span>

        
    
    <input type="text" id="" class=" mt-2 rounded-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="" placeholder="Enter Search"
    
    value= {search} onChange={(e)=>fetchPost(e.target.value)}
    
    />
    
    </div>
        </div>

        
        
      </header>
      <main>
           
<div class="container max-w-3xl px-4 mx-auto sm:px-8">
    <div class="py-8">
        <div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Name
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Email
                            </th>
                          
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                status
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {data.map(item=>{
                            return(
                                <tr>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <div class="flex items-center">
                                       
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                               {item.name}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {item.email}
                                    </p>
                                </td>
                                
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <span class="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                        <span aria-hidden="true" class="absolute inset-0 bg-green-200 rounded-full opacity-50">
                                        </span>
                                        <span class="relative">
                                            active
                                        </span>
                                    </span>
                                </td>
                            </tr>

                            )
                        })}
                       
                    
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>


      </main>
            </div>
        </div>
    </div>
</main>
             {/* Nav */}
            {/* end Nav */}
         
            {/* Footer */}
            <Footer/>
            {/* end Footer */}
        </div>
    )
}

export default SuperStudentsList
