import React,{ useEffect, useState,useContext } from "react";
import {Link, useNavigate} from 'react-router-dom'
import {UserContex} from '../App'
import { ToastContainer, toast } from 'react-toastify';
import QRCode from "qrcode.react";
import 'react-toastify/dist/ReactToastify.css';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Nav_men from "./Nav_men";


const TeacherProfile = ()=>{
  // const {state, dispatch}= useContext(UserContex)

  const history = useNavigate()
   const [name, setName] = useState("")
   const [phone, setPhone] = useState("")
   const [email, setEmail] = useState("")
   const [newAddress, setNewAddress] = useState("")
   const [registration, setRegistration] = useState("")
   const [address,setAddress] = useState([])
   const [department,setDepartment] = useState("")
   const [orderHistory,setOrderHistory] = useState(false) 
   const [prescriptions,setShowPrescriptions] = useState(true) 
   const [edit,setEdit] = useState(false) 
   const [showimage,setShowImage] = useState(false) 
   const [zoomimage,setZoomImage] = useState("") 
   const [url,setUrl] = useState("") 
   const [progress,setProgress] = useState(false) 
   const [orderHistoryData,setOrderHistoryData] = useState([]) 
   const [data,setData] = useState([]) 
   const [event,setEvent] = useState([]) 
   const [memberships,setMemberships] = useState([]) 
   const [search,setSearch] = useState("")
   const [editName,setEditName] = useState(false)
   const navigate= useNavigate()

  const [states,setState] = useState(null)
  //  console.log("STATE",state)

  const {state, dispatch }= useContext(UserContex)


   const monthNames = ["January", "February", "March", "April", "May", "June",
   "July", "August", "September", "October", "November", "December"
 ];
 
 var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
 
 const d = new Date();
 
   


// console.log("ADD",address)

 useEffect(  ()=>{

  
  // setState( JSON.parse( localStorage.getItem("user")))



    fetch('/getmyprofile',{
      method:"get",
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
          "Content-Type":"application/json"
  
          
      },
     
  }).then(res=>res.json())
  .then(result=>{
    if(result.error){
      localStorage.clear()

      navigate('/login')

    }
    console.log(result.details.name)
    setState(result.details)
    setName(result.details.name)
    setEmail(result.details.email)
    setPhone(result.details.phone)
    setUrl(result.details.pic)
    // setEvent(result..event)

      // setOrderHistoryData(result.orders)
      
  })
   

},[])



const updateProfile =()=>{

  fetch("/updateConsumerAddress",{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        address:address
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
      toast.error(data.error)

    }
    else{
   toast.success("Updated")
  //  history.go('/cart')

  setEdit(false)
    }
})
.catch(err=>{
    console.log(err)
})

}

// console.log("DATA",data[0].address)

const fetchPost=(query)=>{


  setSearch(query)

  fetch('/searchall-events',{

    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      query,
    })

  }).then(res=> res.json())
    .then(results=>{
      
      setData(results.events)
    })


}

const handleSearch = event => {
  const term = event.target.value;
  setSearch(term);

  // Perform the search and update searchResults
  const results = data.filter(item =>
    item.toLowerCase().includes(search.toLowerCase())
  );
  setData(results);
};



const postUserUpdate = ()=>{

  fetch('/updateTeacherDetails',{

    method:"put",
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer " +localStorage.getItem("jwt")

    },
    body: JSON.stringify({
     
      name:name,
      email,
      phone,
      pic:url,
    
      
    })
    
  }).then(res=>res.json())
  .then(data=>{
    if(data.error){
      console.log("Error")
    }

    else{
      window.location.reload();
      // history.go('/dashboard')
    }
  })
  .catch(err=>{
    console.log(err)
  })

}


const Logout = ()=>{
  localStorage.clear()
  dispatch({type:"CLEAR"})
      history(`/`)
}



const uploadppt=({target:{files}})=>{

    setProgress(true)
  
   let data = new FormData()
  
   for(const file of files){
  
  data.append('uploadimage', file)
  data.append('name',file.name)
  }
      fetch("/uploadimage", {
  
        method:"post",
        body: data
    })
    .then(res=>res.json())
    .then(data=>{
  
          setUrl(data.url)
          setProgress(false)
  
     
  
      
    })
    .catch(err=>{
        console.log(err)
    })
  
  }
  
     
  const removePicture = (index,amt) => {
      
      
    setUrl("");
}


    return(
<>
{/* <Header></Header> */}
<Nav_men></Nav_men>
<main className="profile-page">
        <section className="relative block" style={{ height: "500px" }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1492684223066-81342ee5ff30?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')"
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
              <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={states?states.pic
                          
                          : "https://img.freepik.com/free-vector/user-blue-gradient_78370-4692.jpg?t=st=1723444743~exp=1723448343~hmac=110b7f7d9864e9f0169a5c30232fb8d67c047fcfc77e875059af419f8ee275fe&w=1380"
                        
                        }

                        className="shadow-xl rounded-full h-32 w-32 align-middle border-none absolute -m-16 -ml-16 lg:-ml-16"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0">
                      <button
                        className="bg-white active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={()=>Logout()}

                      >
                    <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />  <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg>
                      </button>
                      <button
                        className="bg-white active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={()=>{setEditName(true)}}

                      >
<svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />  <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" /></svg>       </button>
                      {/* <button
                        className="bg-white active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={()=>{if(window.confirm('Send account delete request?'))Logout()}}

                      >
<svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>                      </button> */}

                      {/* <button
                        className="bg-purple-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={()=>history('/findevents')}

                      >
                        Logout 
                      </button> */}
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                    <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-purple-500">
                          {data?data.length:""}
                        </span>
                        <span className="text-sm text-gray-500">Orders</span>
                      </div>
                      {/* <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          10
                        </span>
                        <span className="text-sm text-gray-500">Consultations</span>
                      </div> */}
                      {/* <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          89
                        </span>
                        <span className="text-sm text-gray-500">Comments</span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="text-center mt-10">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-purple-500 mb-2">
                    {states?states.name:""}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-800 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-purple-500"></i>{" "}
                    {states?states.email:""}
                  </div>
                 
                 


                                                    {/* SEARCH */}


    


                </div>
                {editName?<>
                
                <section class="h-auto bg-gray-100/50">
                    <div class="container max-w-2xl mx-auto shadow-md md:w-3/4">
                    <div className="relative p-4 border-t-2 border-purple-400 rounded-lg bg-gray-100/5">
                  <div className="max-w-sm mx-auto md:w-full md:mx-0">
                    <div className="inline-flex items-center space-x-4">
                      <a href="#" className="relative block">
                        <img
                          alt="profile"
                          src={
                            states.pic
                              ? states.pic
                              : "https://img.freepik.com/free-vector/user-blue-gradient_78370-4692.jpg?t=st=1723444743~exp=1723448343~hmac=110b7f7d9864e9f0169a5c30232fb8d67c047fcfc77e875059af419f8ee275fe&w=1380"
                          }
                          className="mx-auto object-cover rounded-full h-16 w-16"
                        />
                      </a>
                      <h1 className="text-gray-600">{states.name}</h1>
                    </div>
                  </div>
                  <svg
                    className="absolute top-2 right-2 h-8 w-8 text-red-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    onClick={()=>setEditName(false)}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                
                        <div class="space-y-6 bg-white">
                            <div class="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
                                <h2 class="max-w-sm mx-auto md:w-1/3">
                                    Account
                                </h2>
                                <div class="max-w-sm mx-auto md:w-2/3">
                                    <div class=" relative ">
                                        <input type="text" id="user-info-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                                        value={email}
                                        onChange={(e)=>{setEmail(e.target.value)}}
                                        
                                        />
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div class="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
                                    <h2 class="max-w-sm mx-auto md:w-1/3">
                                        Personal info
                                    </h2>
                                    <div class="max-w-sm mx-auto space-y-5 md:w-2/3">
                                        <div>
                                            <div class=" relative ">
                                                <input type="text" id="user-info-name" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Name"
                                                  value={name}
                                                  onChange={(e)=>{setName(e.target.value)}}
                                                  
                                                />
                                                </div>
                                            </div>
                                            <div>
                                                <div class=" relative ">
                                                    <input type="text" id="user-info-phone" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Phone number"
                                                    
                                                    value={phone}
                                                    onChange={(e)=>{setPhone(e.target.value)}}
                
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>

                                        <div class="items-center w-full p-8 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
                        <div className="w-full mb-5 inline-flex justify-center p-2 md:col-span-1">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal p-2">Upload Profile Image</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>



 

    
                               </div>          
    {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}


    <div class="container flex flex-col items-center justify-center w-full mx-auto ">
    <ul class="grid  grid-cols-1">

    <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                  <a href="#" class="relative block">
                      <img alt="profil" src={url} class="mx-auto object-cover rounded-sm h-48 w-48 "/>
                  </a>
             
             
            
              <button class="flex justify-end w-24 text-right"
              onClick={()=>{removePicture()}}
              >
              <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
              </button>
          </div>
    
      
    </ul>
</div>

            
                            </div>
                            <hr/>
                                       
                                            <div class="w-full px-4 pb-4 ml-auto text-gray-500 md:w-1/3">
                                                <button type="submit" class="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                                
                                                onClick={()=>{postUserUpdate();setEditName(false)}}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                
                                </>:<></>}

                {/* <div class="container max-w-3xl px-4 mx-auto sm:px-8">
    <div class="py-8">
        <div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                membership
                            </th>
                           
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Created at
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                status
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Donate
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                          {memberships?memberships.map(item=>{

                              return(
                                <tr>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <div class="flex items-center">
                                        <div class="flex-shrink-0">
                                            <a href="#" class="relative block">
                                                <img alt="profil" src={item.pic} class="mx-auto object-cover rounded-full h-10 w-10 "/>
                                            </a>
                                        </div>
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                                {item.name}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                              
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <p class="text-gray-900 whitespace-no-wrap">
                                        {item.date.substring(0,10)}
                                    </p>
                                </td>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    <span class="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                        <span aria-hidden="true" class="absolute inset-0 bg-green-200 rounded-full opacity-50">
                                        </span>
                                        <span class="relative">
                                          {item.userStatus}
                                        </span>
                                    </span>
                                </td>
                                <td class="px-5 py-5 text-sm bg-white border-b border-gray-200"
                                >
                                  <a href={`singlemembership/${item._id}`}>
                                    <span class="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                        <span aria-hidden="true" class="absolute inset-0 bg-green-200 rounded-full opacity-50">
                                        </span>
                                        <span class="relative">
                                          Donate now
                                        </span>
                                    </span>
                                  </a>
                                    
                                </td>
                            </tr>
                              )

                          }):<></>}
                       
                      
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> */}

  {orderHistory?
            
<div class="container max-w-4xl mx-auto sm:px-8">
    <div class="">
        <div class="px-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Product
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Quantity
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Price
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Status
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>

                        {orderHistoryData?orderHistoryData.map(item=>{

                            return(
                              <>
                                { item.orderItem?item.orderItem.map(order=>{
                                  console.log(order.product)
                                                      return(

                            <tr>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200 h-8">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0">

                                        <a href={`/singleproduct/${order.product._id}`} class="relative block">
                                            <img alt="medicine" src={order.product && order.product.pic? order.product.pic[0]:"/pcm.png"} class="mx-auto object-content  h-24 w-32 "/>
                                        </a><p class="text-gray-900 whitespace-no-wrap">
                                            {
                                              order.product.name
                                            }

                                        </p>
                                    </div>
                                    <div class="ml-3">
                                        
                                    </div>
                                </div>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    
<div class="flex items-center">
    
   
        {order.qty}
    
  
   
    
</div>
                                </p>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    Nu. {order.product.rate}
                                </p>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    {item.status}
                                </p>
                            </td>
                          
                        </tr>
                          )
                          }):<></>}

                              </>
                            )

                       

                        }):<></>}

                       
                       
                       
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>:<></>
  }

  {prescriptions?<>
  
    <div className="grid  grid-cols-1  md:grid-cols-3  md:gap-4      ">


      {data!=null?data.map(item=>{


return(

<>

<div class="flex ">
              <div className="flex max-w-md overflow-hidden bg-white  rounded-lg shadow-lg"
               
              >
                <div className="w-1/2 bg-fit ">

                    <img class="h-full " src={item.event?item.event.pic:""}/>

                </div>
                <div className="w-2/3 p-4">
                  <h1 className="text-2xl font-bold text-black">{item.event? item.event.name:""}</h1>
                
                  <div class="flex items-center">
                  <svg class="h-8 w-8 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg> 
             <p class="text-md text-black  ml-2">
            
             {item.event?item.event.date.substring(0,10):""}
        </p>
    </div>
                  <div class="flex items-center">
            <svg class="h-8 w-8 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black  ml-2">
            
            {item.event?item.event.venue:""}
        </p>
    </div>


    
              <div class="flex items-center">
                  <svg class="h-8 w-8 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
           SEAT: {item.seat} 
        </p>
    </div>
          
                  <div className="flex justify-between mt-3 item-center">
                    
                    <h1 className="text-xl font-bold text-black">${item.event?item.event.price:""}</h1>
                    <QRCode value={JSON.stringify(item)} size={90}></QRCode>

                  </div>
                </div>
              </div>




            </div>

</>
)
      }):<></>}



</div>

  </>:<></>}



  

   


{
edit? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setEdit(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           
          <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Address</span>

          <div class='flex flex-row'>
          <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Dzongkhag, Area"
            value={newAddress}
            onChange={(e)=>setNewAddress(e.target.value)}
            >

            </input>

            <svg class="h-10 w-10 text-green-500 ml-3 cursor-pointer"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
            
            onClick={()=>{
            //   addAddress(newAddress)
            }}

            >  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="10" y1="14" x2="21" y2="3" />  <path d="M21 3L14.5 21a.55 .55 0 0 1 -1 0L10 14L3 10.5a.55 .55 0 0 1 0 -1L21 3" /></svg>          </div>
           
          </div>


<div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow">
    <ul class="flex flex-col divide-y divide">

            {address.map(item=>{
              return(
<li class="flex flex-row">
            <div class="flex items-center flex-1 p-4 cursor-pointer select-none">
                <div class="flex flex-col items-center justify-center w-10 h-10 mr-4">
                <svg class="h-8 w-8 text-green-300"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="18" y2="6.01" />  <path d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5" />  <polyline points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15" />  <line x1="9" y1="4" x2="9" y2="17" />  <line x1="15" y1="15" x2="15" y2="20" /></svg>                </div>
                <div class="flex-1 pl-1 mr-16">
                    <div class="font-medium ">
                        {item}
                    </div>
                   
                </div>
                {/* <div class="text-xs text-gray-600 dark:text-gray-200">
                    6:00 AM
                </div> */}
            </div>
        </li>
      
              )
            })}

        
      
    </ul>
</div>


          {/* <div className="py-1 px-2">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div> */}

          <div class="py-5 rounded-t sm:px-6">
    <div class="overflow-auto bg-white shadow sm:rounded-md">
      
        <div class="w-full p-4 mx-auto ">
            <button type="button" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={()=>{
              updateProfile()
                // postOrder(data)
                
              
              }}
            >
                Update
            </button>


        </div>
    </div>
</div>




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}





                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-gray-800">
                        {/* Keep track of your s, Track your Event status. View your history and connect with us. */}
                      </p>
                      {/* <a
                        href="#pablo"
                        className="font-normal text-pink-500"
                        onClick={e => e.preventDefault()}
                      >
                        Show more
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>

      <Footer page="profile"></Footer>
</>
    );

}

export default TeacherProfile; 